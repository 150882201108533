import React from "react"
import { Helmet } from "react-helmet"

import { projectName } from "../config"
import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { scrollToTop } from "../includes/commonFunctions"
import "./policy.css"

class DeliveryInformation extends React.Component {
    componentDidMount = () => {
        scrollToTop()
    }
    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Delivery Information",
                url: null,
                active: true,
            },
        ]

        return (
            <>
                <Helmet>
                    <title>{`Delivery Information | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center mb-0 pb-0">
                                    DELIVERY INFORMATION
                                </h3>
                                <hr className="mt-2" />

                                <p className="mb-3">
                                    For all orders placed through bank transfer,
                                    the following delivery schedule will be
                                    applicable from the date of payment credited
                                    into our bank account.
                                </p>
                                <p>
                                    Your order will be dispatched within three
                                    days from the date of payment credited to
                                    our bank account. Shipping / delivery of
                                    books may be done through India Post or the
                                    best mode of shipping based on the shipping
                                    address.
                                </p>
                                <p>
                                    Orders placed through our online portal will
                                    usually be executed with following delivery
                                    deadlines:
                                    <ul className="list-disc pl-5 mt-1">
                                        <li>
                                            To all metros and state capitals in
                                            India - 7 days
                                        </li>
                                        <li>
                                            To all district headquarters - 10
                                            days
                                        </li>
                                        <li>To any other place - 12-15 days</li>
                                    </ul>
                                </p>
                                <p>
                                    The tariff for shipping / delivery charges
                                    should be paid while confirming the order.
                                </p>
                                <p className="mb-3">
                                    For better delivery services, the complete
                                    delivery address, with PIN Code and
                                    telephone / mobile number should be
                                    mentioned in the order.
                                </p>
                                <h4>DELIVERY CHARGES:</h4>
                                <p className="mb-3">
                                    Flat charge of INR 50 on purchases worth
                                    upto INR 500.
                                </p>
                                <p>
                                    Free shipping on purchases worth INR 500 and
                                    above.
                                </p>
                                <h4>TRACKING ORDERS:</h4>
                                <p>
                                    We will email you the name of the courier
                                    company and the tracking number of your
                                    consignment to your registered email
                                    address. In case you do not receive mail
                                    from us within 48 hours of placing an order,
                                    please check your Spam folder. Tracking may
                                    not appear online for up to another 24 hours
                                    in some cases, so please wait until your
                                    package is scanned by the courier company
                                    and their website is updated.
                                </p>
                                <p>
                                    This service is conditional and depends on
                                    the courier service.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default DeliveryInformation
