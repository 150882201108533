import * as Yup from 'yup';

const ProfileDetailsSchema = Yup.object().shape({
    fname: Yup.string()
        .required('Please enter your first name'),
    lname: Yup.string()
        .required('Please enter your last name'),
    mobile: Yup.number()
        .required('Please enter your mobile number')
        .test('length', 'Invalid mobile number', val => val && val.toString().length === 10),
    email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
})

export default ProfileDetailsSchema