import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ data }) => {
    return (
        data ? (
            <nav aria-label="breadcrumb" className="breadcrumb-nav mb-0">
                <div className="container">
                    <ol className="breadcrumb">  

                        {
                            data.map(link => {
                                let isActive = link.active ? `active`: null
                                return(
                                    <li className={`breadcrumb-item ${isActive}`}><Link to={ isActive ? '#0' : link.url }>{link.title}</Link></li>
                                )
                            })
                        }

                    </ol>
                </div>
            </nav>
        ) : null
    )
}

export default Breadcrumb