import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import {Form, Formik, Field} from 'formik'
import { connect } from 'react-redux'

import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import Sidebar from './Sidebar'
import ProfileDetailsSchema from './ProfileDetailsValidation'
import ToastNotification from '../includes/notifications/ToastNotification'
import ErrorMsg from '../includes/form-inputs/ErrorMsg'
import { projectName } from '../config'
import { updateUserInfo } from '../../actions'
import LoadingBtn from '../includes/buttons/LoadingBtn'

class MyProfile extends Component {

    submitProfileFrom = (values, actions, user_id) => {
        this.props.updateUserInfo(values, actions, user_id)
    }

    textInput = ({ name, label, placeholder, type, classnames, field }) => {
        const inputClassnames = classnames ? classnames : `mb-1`;
        return (
            <>
                <label>{label}</label>
                <input type={type} name={name} className={`form-control ${inputClassnames}`} placeholder={placeholder} {...field} />
            </>
        )
    }

    render() {

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'My Account',
                url: null,
                active: true
            }
        ]

        const { user } = this.props.userData
        const isSubmitting = this.props.isSubmitting

        return (
            <>
                <Helmet>
                    <title>{`Profile Information | ${projectName}`}</title>
                </Helmet>

                <main className="main">
                    <Breadcrumb data={breadcrumbData} />

                    <div className="container my-5">
                        <div className="row">
                            <div className="col-lg-9 order-lg-last dashboard-content">
                                <h2>Edit Account Information</h2>
                                <Formik
                                    initialValues={{
                                        fname: user ? user.fname : '',
                                        lname: user ? user.lname : '',
                                        email: user ? user.email : '',
                                        mobile: user ? user.mobile : ''
                                    }}
                                    onSubmit={(values, actions) => {
                                        this.submitProfileFrom(values, actions, user && user.id);
                                    }}
                                    enableReinitialize = {true}
                                    validationSchema={ProfileDetailsSchema}
                                >{(props: FormikProps<any>) => (
                                    <Form className="">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <Field component={this.textInput} label="First Name" name="fname" classnames="mb-1" placeholder="First Name" type="text"></Field>
                                                {props.errors.fname && props.touched.fname && <ErrorMsg msg={props.errors.fname} />}
                                            </div>
                                            <div className="col-lg-6">
                                                <Field component={this.textInput} label="Last Name" name="lname" classnames="mb-1" placeholder="Last Name" type="text"></Field>
                                                {props.errors.lname && props.touched.lname && <ErrorMsg msg={props.errors.lname} />}
                                            </div>
                                            <div className="col-lg-6">
                                                <Field component={this.textInput} label="Email" name="email" placeholder="Email" type="email"></Field>
                                                {props.errors.email && props.touched.email && <ErrorMsg msg={props.errors.email} />}
                                            </div>
                                            <div className="col-lg-6">
                                                <Field component={this.textInput} label="Mobile No" readonly name="mobile" placeholder="Mobile No" type="number"></Field>
                                                {props.errors.mobile && props.touched.mobile && <ErrorMsg msg={props.errors.mobile} />}
                                            </div>
                                        </div>
                                        <div className="form-footer">
                                            <div className="form-footer-right">
                                                <button type="submit" className="btn btn-md btn-primary" disabled={ isSubmitting ? true : false }>{ isSubmitting ? <LoadingBtn /> : 'Save' }</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                                </Formik>
                            </div>
                            <Sidebar page="my-profile" />
                        </div>
                    </div>

                    <ToastNotification />
                </main>
            </>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userData,
        isSubmitting: state.commonFunctions.isSubmitting
    }
}

export default connect(mapStateToProps, { updateUserInfo })(MyProfile)