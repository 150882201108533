import React, { Component } from 'react'
import { connect } from 'react-redux'
import Slider from "react-slick"
import { Link } from 'react-router-dom'

import CategoryItem from './CategoryItem'
import { getHomepageMainCategories } from '../../../actions'
import './categories.css'
import { LeftArrow, RightArrow } from '../../includes/buttons/CustomArrows'
import {staticURL} from '../../config'

class Categories extends Component {

    componentDidMount(){
        this.props.getHomepageMainCategories(8)
    }

    render() {

        const settings = {
            dots: false,
            infinite: false,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            nextArrow: <LeftArrow />,
            prevArrow: <RightArrow />,
            responsive: [
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                }
            ]
        }

        const { loading, data } = this.props.categories

        const categories_list = !loading && data.length > 0 ? data.map( category => {
            return (
                <CategoryItem key={category.id} title={category.name} image={category.api_image} slug={category.slug} />
            )
        }) : null

        return (
            <div className="products-section container pt-0 box001">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="nav nav-tabs mb-2" role="tablist">
                            <li className="nav-item">
                                <a href="#0" className="nav-link active">our popular categories</a>
                            </li>
                        </ul>

                        <div className="row">
                            <div className='col-md-9'>
                                <Slider {...settings} className='main-slide'>
                                    {categories_list}
                                </Slider>
                            </div>
                            <div className='col-md-3 d-none d-md-block'>
                                <div className="inner-quickview inner-icon">
                                    <figure>
                                        <Link to="/all-categories">
                                            <img src={`${staticURL}assets/images/category/1.png`} className='view-all-cats' alt="view all categories" />
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.homePage.mainCategories
    }
}

export default connect(mapStateToProps, { getHomepageMainCategories })(Categories)