import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import CheckoutProgressBar from "./CheckoutProgressBar"
import { staticURL } from "../config"
import OrderSummary from "./OrderSummary"
import { getMyAddresses, selectAddress } from "../../actions"

class Address extends Component {
    componentDidMount() {
        this.props.getMyAddresses()
        //scroll to top
        window.scrollTo(0, 0)
    }

    selectAddress = (id) => {
        //remove selected from any other address
        const elements = document.querySelectorAll(".shipping-address-box")
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index]
            element.classList.contains("active") &&
                element.classList.remove("active")
        }
        //make the address selected
        document.querySelector(`#address-item-${id}`).classList.add("active")

        this.props.selectAddress(id)
    }

    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Cart",
                url: "/cart",
                active: false,
            },
            {
                id: "3",
                title: "Checkout",
                url: null,
                active: true,
            },
        ]

        const { login, loginLoading, cartItems, addresses, selectedAddress } =
            this.props.userData

        if (!loginLoading && !login) {
            window.location.href = `${staticURL}login`
        }

        const myAddresses =
            addresses && addresses.length > 0
                ? addresses.map((address) => {
                      return (
                          <div
                              className={`shipping-address-box ${
                                  selectedAddress === address.id && "active"
                              }`}
                              id={`address-item-${address.id}`}
                              key={`address-${address.id}`}
                          >
                              <address>
                                  {address.name} <br />
                                  {address.address_line_1},{" "}
                                  {address.address_line_2} <br />
                                  {address.city} <br />
                                  {address.state}, {address.pincode} <br />
                                  {address.mobile} <br />
                              </address>
                              <div className="address-box-action clearfix">
                                  <Link to="#0" className="btn btn-sm btn-link">
                                      Edit
                                  </Link>
                                  <a
                                      href="#0"
                                      onClick={() =>
                                          this.selectAddress(address.id)
                                      }
                                      className="btn btn-sm btn-outline-secondary float-right"
                                  >
                                      Ship Here
                                  </a>
                              </div>
                          </div>
                      )
                  })
                : null

        return (
            <main className="main">
                <Breadcrumb data={breadcrumbData} />

                <div className="container my-5 cart-align">
                    <div className="text-center">
                        <CheckoutProgressBar step="0" />
                    </div>
                    <div className="row box001">
                        <div className="col-lg-8">
                            <ul className="checkout-steps">
                                <li>
                                    <h2 className="step-title">
                                        Shipping Address
                                    </h2>

                                    <div className="shipping-step-addresses">
                                        {myAddresses}
                                    </div>
                                </li>
                            </ul>

                            <div className="d-flex justify-content-between">
                                <Link
                                    to="/add-new-address?p=checkout/addresses"
                                    className="btn btn-sm btn-outline-secondary btn-new-address"
                                >
                                    + Add New Address
                                </Link>
                                {selectedAddress && (
                                    <Link
                                        to={`/checkout/payment/${selectedAddress}`}
                                        style={{ marginBottom: "5px" }}
                                        className="btn btn-sm btn-primary"
                                    >
                                        Proceed To Payment
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <OrderSummary cartItems={cartItems} />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
    }
}

export default connect(mapStateToProps, { getMyAddresses, selectAddress })(
    Address
)
