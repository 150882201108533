import React from 'react'

const Sidebar = ({ category, subCategories }) => {
    return (
        <>
            <div className="sidebar-overlay"></div>
                            
            <aside className="sidebar-shop col-lg-3 order-lg-first">
                {/* <div className="sidebar-wrapper">
                    <div className="widget">
                        <h3 className="widget-title">
                            <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">Categories</a>
                        </h3>

                        <div className="collapse show" id="widget-body-2">
                            <div className="widget-body">
                                <ul className="cat-list">
                                    <li><a href="#0">Accessories</a></li>
                                    <li><a href="#0">Watch Fashion</a></li>
                                    <li><a href="#0">Tees, Knits &amp; Polos</a></li>
                                    <li><a href="#0">Pants &amp; Denim</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
            </aside>
        </>
    )
}

export default Sidebar;
