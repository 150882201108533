import React from "react"
import { Helmet } from "react-helmet"

import { projectName } from "../config"
import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { scrollToTop } from "../includes/commonFunctions"
import "./policy.css"

class CancellationPolicy extends React.Component {
    componentDidMount = () => {
        scrollToTop()
    }
    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Cancellation & Refund Policy",
                url: null,
                active: true,
            },
        ]

        return (
            <>
                <Helmet>
                    <title>{`Cancellation & Refund Policy | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center mb-0 pb-0">
                                    CANCELLATION & REFUND POLICY
                                </h3>
                                <hr className="mt-2" />

                                <p className="mb-3">
                                    No cancellations & refunds are entertained.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default CancellationPolicy
