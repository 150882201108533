import React from 'react'

import './loading-btn.css'

const LoadingBtn = () => {
    return(
        <div className="d-flex justify-content-center" style={{ alignItems: 'center' }}>
            <div className="lds-ring mr-3"><div></div><div></div><div></div><div></div></div>
            Please Wait...
        </div>
    )
}

export default LoadingBtn