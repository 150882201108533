import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import DynamicError from '../includes/errors/DynamicError'
import Sidebar from './Sidebar'
import './my-account.css'
import { getMyAddresses, showModal, closeModal, deleteAddress, selectAddress } from '../../actions'
import PageModal from '../includes/modal/PageModal'
import { Link } from 'react-router-dom'

class MyAddresses extends Component {

    componentDidMount(){
        this.props.getMyAddresses()
    }

    showConfirmModal = (id) => {
        this.props.selectAddress(id)
        this.props.showModal('confirm-delete')
    }

    closeConfirmModal = () => {
        this.props.closeModal('confirm-delete')
    }

    confirmDelete = (id) => {
        this.props.deleteAddress(id)
        
        //ask confirmation from user window.confirm
        // if(window.confirm('Are you sure you want to delete this address?')){
        //     this.props.deleteAddress(id)
        // }

    }

    render() {

        const { addresses, selectedAddress } = this.props.userData
        const { modalOpen, modalName } = this.props.modal

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'My Account',
                url: null,
                active: true
            }
        ]

        return (
            <>
                <Helmet>
                    <title>My Address Book</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />

                    <div className="container my-5">
                        <div className="row">
                            <div class="col-lg-9 order-lg-last dashboard-content">
                                <div className='row'>
                                    <div className='col-12 d-flex flex-row-reverse mb-2'>
                                        <Link to="/add-new-address?p=my-addresses" className="btn btn-sm btn-primary">Add New Address</Link>
                                    </div>
                                    {
                                        addresses && addresses.length > 0 ? addresses.map(address => {
                                            return(
                                                <div className='col-md-6'>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className='card-edit adr-btns'>
                                                                <Link to={`/edit-address/${address.id}?p=my-addresses`} className='btn btn-sm btn-primary'>Edit</Link>
                                                                {/* <button onClick={() => this.showConfirmModal(address.id)} className='btn btn-sm btn-danger'>Delete</button> */}
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <h4>{address.name}</h4>
                                                            <addresses>
                                                                {`${address.address_line_1}, ${address.address_line_2 ? address.address_line_2 : ''}`} <br/>
                                                                {`${address.city}, ${address.state}, ${address.pincode}`} <br/>
                                                                {address.mobile}
                                                            </addresses>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : (
                                            <div className='col-12'>
                                                <DynamicError image="assets/custom/address-book.png" title="Your address book is empty. Add new address." btn={false} btnText="Go to home" url="/" />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <Sidebar page="my-addresses" />
                        </div>
                    </div>
                </main>
                
                {
                    modalOpen && modalName === 'confirm-delete' ? (
                        <PageModal>
                            <div className='confirm-delete text-center'>
                                <h4>Are you sure you want to delete this address?</h4>
                                <div>
                                    <button onClick={() => this.confirmDelete(selectedAddress)} className='btn btn-sm btn-primary mr-3'>Yes</button>
                                    <button onClick={() => this.closeConfirmModal()} className='btn btn-sm btn-outline-secondary'>Cancel</button>
                                </div>
                            </div>
                        </PageModal>
                    ) : null
                }
                
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userData,
        modal: state.modal
    }
}

export default connect(mapStateToProps, { getMyAddresses, showModal, closeModal, deleteAddress, selectAddress })(MyAddresses)
