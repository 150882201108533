import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import { projectName } from '../config'

class ContactUs extends Component {
    render() {

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'Contact Us',
                url: null,
                active: true
            }
        ]

        return (
            <>
                <Helmet>
                    <title>{`Contact Us | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <div className="row">
                            <div className='col-12'>
                                <div className="mapouter">
                                    <div className="gmap_canvas">
                                        <iframe width="100%" height="370" id="gmap_canvas" src="https://maps.google.com/maps?q=Saraswati%20Market,%20Jaswant%20Road,%20Panbazar%20Gauhati,%20Assam,%20India%20781001&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <hr/>
                                
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h2 className="light-title">Call Us</h2>
                                        <div className="">
                                            <div>
                                                
                                                <p><a href="tel:+919864122631">+91 9864122631</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <h2 className="light-title">Email Us</h2>
                                        <div className="">
                                            <div>
                                                
								                <p><a href="mailto:#">info@aankbaak.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <h2 className="light-title">Visit Us</h2>
                                        <div className="">
                                            <div>
                                                
								                <p>Saraswati Market, Jaswant Road, Panbazar Gauhati, Assam, India 781001</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default ContactUs