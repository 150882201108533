import React from 'react'

const CheckoutProgressBar = ({ step }) => {
  return (
    <ul className="checkout-progress-bar">
        <li className={ step >= 1 ? `active` : ''}>
            <span>Select Address</span>
        </li>
        <li className={ step >= 2 ? `active` : ''}>
            <span>Payment</span>
        </li>
    </ul>
  )
}

export default CheckoutProgressBar
