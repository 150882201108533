import React, { Component } from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { projectName } from "../config"
import FilterNav from "../product-listings/filter-nav/FilterNav"
import {
    getCollectionProducts,
    loadMoreCollectionProducts,
} from "../../actions"
import ProductSingle from "../product-listings/product-single/ProductSingle"
import { scrollToTop } from "../includes/commonFunctions"

class Collection extends Component {
    componentDidMount() {
        scrollToTop()
        this.props.getCollectionProducts(18)
    }

    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Collection",
                url: null,
                active: true,
            },
        ]

        const { data, last_page, current_page } = this.props.collectionData
        const { myWishlistItems } = this.props.userData

        const collection =
            data && data.length > 0
                ? data.map((product) => {
                      const liked_by_user = myWishlistItems.includes(product.id)
                          ? true
                          : false
                      return (
                          <div
                              className="col-6 col-sm-4 col-md-2 box002"
                              key={product.id}
                          >
                              <ProductSingle
                                  name={product.name}
                                  slug={product.slug}
                                  category={product.main_category.name}
                                  category_slug={`/category/${product.main_category.slug}`}
                                  retail_price={product.retail_price}
                                  selling_price={product.selling_price}
                                  image={
                                      product.product_image
                                          ? product.product_image.api_image
                                          : null
                                  }
                                  is_favourite={liked_by_user}
                                  pid={product.id}
                              />
                          </div>
                      )
                  })
                : null

        return (
            <>
                <Helmet>
                    <title>{`Collection | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <FilterNav
                            title="Collection"
                            toggleButton={false}
                            page="collection"
                        />
                        <div className="row box001">{collection}</div>
                        {last_page > current_page ? (
                            <div className="text-center mt-3">
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                        this.props.loadMoreCollectionProducts(
                                            18,
                                            current_page + 1
                                        )
                                    }
                                >
                                    Load More
                                </button>
                            </div>
                        ) : null}
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        collectionData: state.collectionData,
        userData: state.userData,
    }
}

export default connect(mapStateToProps, {
    getCollectionProducts,
    loadMoreCollectionProducts,
})(Collection)
