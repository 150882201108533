import React from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { baseURL } from '../../config'
import { showModal, closeModal, addToWishlist, removeFromWishlist } from '../../../actions'

class ProductSingle extends React.Component {

    redirectToLogin = () => {
        //window.location.href = '/login'
        //let history = useHistory()
        this.props.history.push("/login")
    }

    render(){

        const { pid, image, name, slug, retail_price, selling_price, category, category_slug, is_favourite, page } = this.props

        const { login } = this.props.userData

        return (
            <div className="product-default inner-quickview inner-icon1 box003">
                <figure>
                    <Link to={`/product/${slug}`}>
                        <img src={`${baseURL}${image}`} alt={name} />
                    </Link>
                    {/* <div className="label-group">
                        <span className="product-label label-sale">-20%</span>
                    </div> */}
                    {
                        is_favourite ? (
                            <div className="btn-icon-group">
                                <button className="btn-icon" onClick={() => this.props.removeFromWishlist(pid, page)}><i className="icon-heart-1" style={{ color: '#ff0000' }}></i></button>
                            </div>
                        ) : (
                            <div className="btn-icon-group">
                                <button className="btn-icon" onClick={() => login ? this.props.addToWishlist(pid, page) : this.redirectToLogin()}><i className="icon-heart-1"></i></button>
                            </div>
                        )
                    }
                    
                    
                </figure>
                <div className="product-details">
                    <div className="category-wrap">
                        <div className="category-list">
                            <Link to={category_slug} className="product-category">{category}</Link>
                        </div>
                    </div>
                    <h3 className="product-title">
                        <Link to={`/product/${slug}`} className="ellipsis" title={name}>{name}</Link>
                    </h3>
                        <div className="ratings-container">
                            <div className="product-ratings">
                                <span className="ratings" ></span>
                                <span className="tooltiptext tooltip-top"></span>
                            </div>
                        </div>
                        <div className="price-box">
                            <span className="old-price">₹{retail_price}</span>
                            <span className="product-price">₹{selling_price}</span>
                        </div>
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        modal: state.modal
    }
}

export default withRouter(connect(mapStateToProps, { showModal, closeModal, addToWishlist, removeFromWishlist })(ProductSingle))