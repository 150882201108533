import React from "react"
import { Helmet } from "react-helmet"

import { projectName } from "../config"
import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { scrollToTop } from "../includes/commonFunctions"
import "./policy.css"

class PrivacyPolicy extends React.Component {
    componentDidMount = () => {
        scrollToTop()
    }
    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Privacy Policy",
                url: null,
                active: true,
            },
        ]

        return (
            <>
                <Helmet>
                    <title>{`Privacy Policy | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center mb-0 pb-0">
                                    PRIVACY POLICY
                                </h3>
                                <hr className="mt-2" />

                                <p className="mb-3">
                                    At Aank Baak, we respect your right to
                                    privacy. The personal information shared and
                                    exchanged with us will not be shared, sold,
                                    disclosed or revealed to any third party in
                                    any form, for any purpose, at any time. All
                                    contacts and sales information, including
                                    the information / queries from us, will
                                    remain confidential at all times.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default PrivacyPolicy
