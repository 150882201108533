import React from "react"
import { Helmet } from "react-helmet"

import { projectName } from "../config"
import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { scrollToTop } from "../includes/commonFunctions"
import "./policy.css"

class TermsConditions extends React.Component {
    componentDidMount = () => {
        scrollToTop()
    }
    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Terms & Conditions",
                url: null,
                active: true,
            },
        ]

        return (
            <>
                <Helmet>
                    <title>{`Terms & Conditions | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center mb-0 pb-0">
                                    TERMS & CONDITIONS
                                </h3>
                                <hr className="mt-2" />
                                <h4>PAYMENT:</h4>
                                <ul className="list-num mb-3">
                                    <li>
                                        ADVANCE PAYMENT IS NECESSARY BEFORE
                                        CONFIRMATION OF THE ORDER.
                                    </li>
                                    <li>
                                        PAYMENT ONCE CONFIRMED IS NOT
                                        REFUNDABLE.
                                    </li>
                                </ul>
                                <h4>RESOLUTION OF DISPUTES:</h4>
                                <p className="mb-3">
                                    In case of any dispute / legal claim, the
                                    matter may be settled under Guwahati
                                    jurisdiction only.
                                </p>
                                <h4>INTELLECTUAL PROPERTY RIGHTS:</h4>
                                <p>
                                    The website (including, but not limited to,
                                    all information, software, text, displays,
                                    images, illustrations, video and audio, and
                                    the design, selection and arrangement
                                    thereof), are owned by Aank Baak and are
                                    protected by Indian and international
                                    copyright, trademark, patent, trade secret
                                    and other intellectual property or
                                    proprietary rights laws.
                                </p>
                                <p className="mb-3">
                                    You may access the Website for your
                                    personal, non-commercial use only. You must
                                    not reproduce, distribute, modify, create
                                    derivative works of, publicly display,
                                    publicly perform, republish, download, store
                                    or transmit any of the material on our
                                    website, except as follows:
                                    <ul className="list-disc pl-5 mt-1">
                                        <li>
                                            If we provide desktop, mobile or
                                            other applications for download, you
                                            may download a single copy to your
                                            computer or mobile device solely for
                                            your own personal, non-commercial
                                            use, provided you agree to be bound
                                            by our end user license agreement
                                            for such applications.
                                        </li>
                                        <li>
                                            No other use is permitted without
                                            prior written permission of Aank
                                            Baak.
                                        </li>
                                        <li>
                                            You must not access or use, for any
                                            commercial purposes, any part of the
                                            website or any services or materials
                                            available through the Website.
                                        </li>
                                        <li>
                                            If you print, copy, modify, download
                                            or otherwise use or provide any
                                            other person with access to any part
                                            of the website in breach of the
                                            Terms of Use, your right to use the
                                            website will cease immediately and
                                            you must, at our option, return or
                                            destroy any copies of the materials
                                            you have made. No right, title or
                                            interest in, or to, the website, or
                                            any content on the website is
                                            transferred to you, and all rights
                                            not expressly granted are reserved
                                            by Aank Baak. Any use of the website
                                            not expressly permitted by these
                                            Terms of Use is a breach of these
                                            Terms of Use and may violate
                                            copyright, trademark and other laws
                                        </li>
                                    </ul>
                                </p>
                                <h4>COPYRIGHT:</h4>
                                <p className="mb-3">
                                    All contents on the website, including text,
                                    graphics, logos, images, audio or visual
                                    clips, downloads and software, and the
                                    compilations of content, are the property of
                                    Aank Baak.
                                </p>
                                <h4>PRODUCT DESCRIPTIONS:</h4>
                                <p>
                                    The website attempts to be as accurate as
                                    possible. However, we do not warrant that
                                    product descriptions or other content are
                                    accurate, complete, reliable, current, or
                                    error-free.
                                </p>
                                <p>
                                    Products available on the website may be
                                    viewed on the website, and therefore you
                                    acknowledge and agree that on our website,
                                    the actual sizes and shapes of the goods may
                                    differ from how they appear on your screen;
                                    and pictures and images on the website are
                                    for illustration purposes only.
                                </p>
                                <p className="mb-3">
                                    We reserve the right to adjust / revise
                                    prices, products and special offers any time
                                    at our discretion.
                                </p>
                                <h4>ADDRESS FOR COMMUNICATION:</h4>
                                <address>
                                    Aank Baak <br />
                                    House No. 3, Bylane 5, Natun Saraniya,
                                    Gandhibasti <br />
                                    Guwahati, Assam, India <br />
                                    Tel.:{" "}
                                    <a href="tel:919864568005">
                                        +91 98645 68005
                                    </a>{" "}
                                    <br />
                                    Email:{" "}
                                    <a href="mailto:aankbaak@aankbaak.com">
                                        aankbaak@aankbaak.com
                                    </a>
                                </address>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default TermsConditions
