import { api } from "../api"
import { toast } from "react-toastify"

/*************** Fetching Homepage Data ****************/
//Get Carousel data
export const getHomepageCarousel = () => {
    return async (dispatch) => {
        const url = `/homepage/carousel`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_HOMEPAGE_CAROUSEL",
            payload: response,
        })
    }
}

//Get Latest Products
export const getLatestProducts = (limit) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/homepage/latest-products/${limit}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_LATEST_PRODUCTS",
            payload: response,
        })
    }
}

//Get Featured Authors
export const getFeaturedAuthors = (limit) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/homepage/featured-authors/${limit}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_FEATURED_AUTHORS",
            payload: response,
        })
    }
}

//Get Featured Products
export const getFeaturedBooks = (limit) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/homepage/product-tag/F/${limit}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_FEATURED_BOOKS",
            payload: response,
        })
    }
}

//Get Bestseller Products
export const getBestsellerBooks = (limit) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/homepage/product-tag/B/${limit}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_BESTSELLER_BOOKS",
            payload: response,
        })
    }
}

//Get Trending Products
export const getTrendingBooks = (limit) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/homepage/product-tag/T/${limit}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_TRENDING_BOOKS",
            payload: response,
        })
    }
}

//Get Homepage Categories
export const getHomepageMainCategories = (limit) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/main-categories/${limit}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_HOMEPAGE_MAIN_CATEGORIES",
            payload: response,
        })
    }
}

/*************** Fetching Common Data ****************/
//Get Common data
export const getCommonData = () => {
    return async (dispatch) => {
        const url = `/common-data`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_COMMON_DATA",
            payload: response,
        })
    }
}

// My Wishlist IDs
export const getWishlistIds = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/my-wishlist-ids`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_WISHLIST_IDS",
            payload: response,
        })
    }
}

/*************** Common Functions ****************/
const startSubmitting = () => {
    return {
        type: "START_SUBMITTING",
    }
}

const stopSubmitting = () => {
    return {
        type: "STOP_SUBMITTING",
    }
}

const startLoading = () => {
    return {
        type: "START_LOADING",
    }
}

const stopLoading = () => {
    return {
        type: "STOP_LOADING",
    }
}

//Show Modal
export const showModal = (modal) => {
    return {
        type: "OPEN_MODAL",
        payload: modal,
    }
}

//Hide Modal
export const closeModal = (modal) => {
    return async (dispatch) => {
        dispatch({
            type: "CLOSE_MODAL",
            payload: modal,
        })
    }
}

//Add to wishlist
export const addToWishlist = (product_id) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/add-to-wishlist/${product_id}`
        const response = await api
            .post(
                url,
                { token },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "ADD_TO_WISHLIST",
            payload: response,
        })
    }
}

//Remove from wishlist
export const removeFromWishlist = (product_id) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/remove-from-wishlist/${product_id}`
        const response = await api
            .post(
                url,
                { token },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "REMOVE_FROM_WISHLIST",
            payload: response,
        })
    }
}

/*************** Cookie Functions ****************/
/* Set Cookie */
const setCookie = (cname, cvalue, exdays) => {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

/* Check if cookie is present */
const getCookie = (cname) => {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(";")
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === " ") {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

/* Delete Cookie */
const delete_cookie = (name) => {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}

/*************** User Auth ****************/
//User Login
export const userLogin = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/login`
        var formdata = new FormData()

        formdata.append("email", formValues.email)
        formdata.append("password", formValues.password)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "USER_LOGIN",
            payload: response,
        })
        dispatch(stopSubmitting())
        setCookie("userToken", response.data.access_token, 2)
    }
}

//Register User
export const userRegister = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/register`
        var formdata = new FormData()

        formdata.append("fname", formValues.fname)
        formdata.append("lname", formValues.lname)
        formdata.append("email", formValues.email)
        formdata.append("mobile", formValues.mobile)
        formdata.append("password", formValues.password)
        formdata.append(
            "password_confirmation",
            formValues.password_confirmation
        )

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "USER_REGISTER",
            payload: response,
        })

        dispatch(stopSubmitting())
    }
}

//Update User Information
export const updateUserInfo = (formValues, actions, id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("userToken")
        const url = `/auth/update-profile/${id}`
        var formdata = new FormData()

        formdata.append("fname", formValues.fname)
        formdata.append("lname", formValues.lname)
        formdata.append("email", formValues.email)
        formdata.append("mobile", formValues.mobile)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "UPDATE_USER_INFO",
            payload: response,
        })

        dispatch(stopSubmitting())

        if (response.status === 200) {
            toast.success("Account information successfully updated", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 401) {
            toast.error("You are not logged in. Please login first", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 400) {
            toast.error(response.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else {
            toast.error("Oops! Something went wrong. Try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        }
    }
}

/* Get Logged In User */
export const checkUser = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/me`
        const response = await api
            .post(
                url,
                { token },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "CHECK_USER",
            payload: response,
        })
    }
}

/* Logout User */
export const logout = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/logout`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOGOUT_USER",
            payload: response,
        })
        delete_cookie("userToken")
        window.location.reload()
    }
}

/* My Wishlist */
export const getMyWishlist = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/my-wishlist`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "MY_WISHLIST",
            payload: response,
        })
    }
}

//Load More Wishlist Products
export const loadMoreWishlistProducts = (limit, offset) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const queryOffset = offset && offset > 0 ? `&page=${offset}` : ""

        const token = getCookie("userToken")
        const url = `/auth/my-wishlist${queryLimit}${queryOffset}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOAD_MORE_WISHLIST_PRODUCTS",
            payload: response,
        })
    }
}

/* My Cart */
export const getMyCart = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/my-cart`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "MY_CART",
            payload: response,
        })
    }
}

//Add to cart
export const addToCart = (product_id) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/add-to-cart/${product_id}`
        const response = await api
            .post(
                url,
                { token },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "ADD_TO_CART",
            payload: response,
        })

        if (response.status === 200) {
            toast.success("Product Successfully Added To Cart", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 401) {
            toast.error("Please Login To Add To Cart", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 400) {
            toast.error(response.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else {
            toast.error("Oops! Something went wrong. Try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        }
    }
}

// Decrease Qty From Cart
export const removeFromCart = (product_id) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/remove-from-cart/${product_id}`
        const response = await api
            .post(
                url,
                { token },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "REMOVE_FROM_CART",
            payload: response,
        })

        if (response.status === 200) {
            toast.success("Cart Successfully Updated", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 401) {
            toast.error("Please Login To Your Account", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 400) {
            toast.error(response.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else {
            toast.error("Oops! Something went wrong. Try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        }
    }
}

// Delete from cart
export const deleteFromCart = (product_id) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/delete-from-cart/${product_id}`
        const response = await api
            .post(
                url,
                { token },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "DELETE_FROM_CART",
            payload: response,
        })

        if (response.status === 200) {
            toast.success("Product Removed From Cart", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 401) {
            toast.error("Please Login To Your Account", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 400) {
            toast.error(response.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else {
            toast.error("Oops! Something went wrong. Try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        }
    }
}

//My Addresses
export const getMyAddresses = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/my-addresses`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "MY_ADDRESSES",
            payload: response,
        })
    }
}

// Add New Address
export const addNewAddress = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("userToken")
        const url = `/auth/add-new-address`
        var formdata = new FormData()

        formdata.append("name", formValues.name)
        formdata.append("mobile", formValues.mobile)
        formdata.append("address_line_1", formValues.address_line_1)
        formdata.append("address_line_2", isEmpty(formValues.address_line_2))
        formdata.append("state", formValues.state)
        formdata.append("city", formValues.city)
        formdata.append("pincode", formValues.pincode)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "ADD_NEW_ADDRESS",
            payload: response,
        })
        dispatch(stopSubmitting())

        if (response.status === 200) {
            toast.success("New Address Successfully Added", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 401) {
            toast.error("Please Login To Your Account", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else {
            toast.error("Oops! Something went wrong. Please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        }
    }
}

// Update Address
export const updateAddress = (formValues, actions, id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("userToken")
        const url = `/auth/update-address/${id}`
        var formdata = new FormData()

        formdata.append("name", formValues.name)
        formdata.append("mobile", formValues.mobile)
        formdata.append("address_line_1", formValues.address_line_1)
        formdata.append("address_line_2", isEmpty(formValues.address_line_2))
        formdata.append("state", formValues.state)
        formdata.append("city", formValues.city)
        formdata.append("pincode", formValues.pincode)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "UPDATE_ADDRESS",
            payload: response,
        })
        dispatch(stopSubmitting())

        if (response.status === 200) {
            toast.success("Address Successfully Updated", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 401) {
            toast.error("Please Login To Your Account", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else {
            toast.error("Oops! Something went wrong. Please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        }
    }
}

//delete address
export const deleteAddress = (address_id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("userToken")
        const url = `/auth/delete-address`

        var formdata = new FormData()
        formdata.append("address_id", address_id)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "DELETE_ADDRESS",
            payload: response,
        })
        dispatch(stopSubmitting())

        if (response.status === 200) {
            toast.success("Address Successfully Deleted", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else if (response.status === 401) {
            toast.error("Please Login To Your Account", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        } else {
            toast.error("Oops! Something went wrong. Please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "react-toast-custom-css",
            })
        }
    }
}

//select Address
export const selectAddress = (id) => {
    return {
        type: "SELECT_ADDRESS",
        payload: id,
    }
}

//select payment mode
export const selectPaymentMode = (mode) => {
    return {
        type: "PAYMENT_MODE",
        payload: mode,
    }
}

//select fast delivery
export const selectFastDelivery = (mode) => {
    return {
        type: "FAST_DELIVERY",
        payload: mode,
    }
}

//place order
export const placeOrder = (address, paymentMode, isFastDelivery) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("userToken")
        const url = `/auth/place-order`

        var formdata = new FormData()
        formdata.append("address_id", address)
        formdata.append("payment_mode", paymentMode)

        formdata.append("fast_delivery", isFastDelivery)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.payment_mode === "ONLINE") {
                    openRazorpayUi(res.data)
                }
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "PLACE_ORDER",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

const openRazorpayUi = (values) => {
    var options = {
        key: "rzp_live_JyoXrePvLzFS5h",
        amount: values.amount,
        currency: "INR",
        name: "Aank Baak",
        image: "/assets/images/logo1.jpg",
        order_id: values.order_id,
        handler: async (vals) => {
            const url = `/auth/payment-success`
            var formdata = new FormData()
            const token = getCookie("userToken")

            formdata.append("id", values.id)
            formdata.append("payment_id", vals.razorpay_payment_id)
            formdata.append("order_id", vals.razorpay_order_id)
            formdata.append("razorpay_signature", vals.razorpay_signature)

            await api
                .post(url, formdata, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    window.location.href = `/order-placed/${values.id}`
                })
                .catch((error) => {
                    alert(error.response.data.message)
                })
        },
        prefill: {
            name: values.name,
            email: values.email,
            contact: values.mobile,
        },
        notes: {
            Name: values.name,
        },
        theme: {
            color: "#F37254",
        },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on("payment.failed", function (response) {
        alert(response.error.description)
    })

    rzp1.open()
}

//My Orders
export const getMyOrders = (limit = 15) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/my-orders/${limit}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "MY_ORDERS",
            payload: response,
        })
    }
}

//Get Order Details
export const getOrderDetails = (id) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/order-details/${id}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "ORDER_DETAILS",
            payload: response,
        })
    }
}

//check if value is empty
export const isEmpty = (value) => {
    if (value == undefined || value == null || value.trim().length == 0) {
        return ""
    } else {
        return value
    }
}

/*************** Fetching Products Data ****************/
//Get Main Category Products
export const getMainCategoryProducts = (
    slug,
    limit = 0,
    sort_by = null,
    sort_value = null
) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const querySort =
            sort_by && sort_value
                ? `&sort_by=${sort_by}&sort_value=${sort_value}`
                : ""

        const token = getCookie("userToken")
        const url = `/category/${slug}${queryLimit}${querySort}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_CATEGORY_PRODUCTS",
            payload: response,
        })
    }
}

//Load More Category Products
export const loadMoreCategoryProducts = (slug, limit, offset) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const queryOffset = offset && offset > 0 ? `&page=${offset}` : ""

        const token = getCookie("userToken")
        const url = `/category/${slug}${queryLimit}${queryOffset}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOAD_MORE_CATEGORY_PRODUCTS",
            payload: response,
        })
    }
}

//Get Sub Category Products
export const getSubCategoryProducts = (
    slug,
    limit = 0,
    sort_by = null,
    sort_value = null
) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const querySort =
            sort_by && sort_value
                ? `&sort_by=${sort_by}&sort_value=${sort_value}`
                : ""

        const token = getCookie("userToken")
        const url = `/sub-category/${slug}${queryLimit}${querySort}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_SUB_CATEGORY_PRODUCTS",
            payload: response,
        })
    }
}

//Load More Sub Category Products
export const loadMoreSubCategoryProducts = (slug, limit, offset) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const queryOffset = offset && offset > 0 ? `&page=${offset}` : ""

        const token = getCookie("userToken")
        const url = `/sub-category/${slug}${queryLimit}${queryOffset}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOAD_MORE_SUB_CATEGORY_PRODUCTS",
            payload: response,
        })
    }
}

//Get Collection Products
export const getCollectionProducts = (
    limit = 18,
    sort_by = null,
    sort_value = null
) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const querySort =
            sort_by && sort_value
                ? `&sort_by=${sort_by}&sort_value=${sort_value}`
                : ""

        const token = getCookie("userToken")
        const url = `/collection${queryLimit}${querySort}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_COLLECTION_PRODUCTS",
            payload: response,
        })
    }
}

//Load More Collection Products
export const loadMoreCollectionProducts = (
    limit,
    offset,
    sort_by = null,
    sort_value = null
) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const queryOffset = offset && offset > 0 ? `&page=${offset}` : ""
        const querySort =
            sort_by && sort_value
                ? `&sort_by=${sort_by}&sort_value=${sort_value}`
                : ""

        const token = getCookie("userToken")
        const url = `/collection${queryLimit}${queryOffset}${querySort}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOAD_MORE_COLLECTION_PRODUCTS",
            payload: response,
        })
    }
}

//Get Language Products
export const getLanguageProducts = (language) => {
    return async (dispatch) => {
        const url = `/language/${language}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_LANGUAGE_PRODUCTS",
            payload: response,
        })
    }
}

//Get Product Details
export const getProductDetails = (slug) => {
    return async (dispatch) => {
        const url = `/product/${slug}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_PRODUCT_DETAILS",
            payload: response,
        })
    }
}

//Get Related Products
export const getRelatedProducts = (category_id, product_id, limit) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/related-products/${category_id}/${product_id}/${limit}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_RELATED_PRODUCTS",
            payload: response,
        })
    }
}

/*************** Fetching Authors Data ****************/
//Get Authors List
export const getAuthorsList = (limit, page = 1) => {
    return async (dispatch) => {
        const url = `/authors-list/${limit}?page=${page}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_AUTHORS_LIST",
            payload: response,
        })
    }
}

//Get Single Author Details
export const getAuthorDetails = (slug) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/author/${slug}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_AUTHOR_DETAILS",
            payload: response,
        })
    }
}

/******************** Blogs Data *********************/

//Get Blogs List
export const getBlogsList = (limit) => {
    return async (dispatch) => {
        const url = `/blog-posts?limit=${limit}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_BLOGS_LIST",
            payload: response,
        })
    }
}

//Get Single Blog Details
export const getBlogDetails = (slug) => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/blog/${slug}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_BLOG_DETAILS",
            payload: response,
        })
    }
}

//Load More Blog Posts
export const loadMoreBlogPosts = (limit, offset) => {
    return async (dispatch) => {
        const queryLimit = limit && limit > 0 ? `?limit=${limit}` : ""
        const queryOffset = offset && offset > 0 ? `&page=${offset}` : ""

        const token = getCookie("userToken")
        const url = `/blog-posts${queryLimit}${queryOffset}`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOAD_MORE_BLOG_POSTS",
            payload: response,
        })
    }
}

/************ All Categories *****************/
//Get All Categories
export const getAllMainCategories = (limit) => {
    return async (dispatch) => {
        const url = `/main-categories/${limit}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_ALL_MAIN_CATEGORIES",
            payload: response,
        })
    }
}
