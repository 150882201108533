import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
    fname: Yup.string()
        .required('Please enter your first name'),
    lname: Yup.string()
        .required('Please enter your last name'),
    password: Yup.string()
        .min(2, 'Too Short!')
        .required('Please enter your password'),
    password_confirmation: Yup.string()
        .required('Please confirm your password')
        .test('passwords-match', 'Passwords doesn not match', function(value){
          return this.parent.password === value
        }),
    mobile: Yup.number()
        .required('Please enter your mobile number')
        .test('length', 'Invalid mobile number', val => val && val.toString().length === 10),
    email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
})

export default RegisterSchema