import React, { Component } from "react"
import { Link } from "react-router-dom"

import { projectName, staticURL } from "../config"
import "./footer.css"

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-12 pb-5 pb-lg-0">
                                <div className="widget text-center">
                                    <div className="">
                                        <img src={`${staticURL}assets/images/logo1.jpg`} alt="Logo" className="m-b-3" style={{maxWidth: '120px', borderRadius: '50%', margin: '0 auto'}} />
                                    </div>
                                    <p className="m-b-4">A prominent book publishing house...</p>
                                </div>
                            </div> */}

                            <div className="col-lg-4 col-sm-6 pb-5 pb-lg-0">
                                <div className="widget mb-2">
                                    <h4 className="widget-title mb-1 pb-1">
                                        IMPORTANT LINKS
                                    </h4>
                                    <ul className="contact-info footer-links m-b-4">
                                        <li>
                                            <Link to="/collection">
                                                Collection
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/blogs">Blogs</Link>
                                        </li>
                                        <li>
                                            <Link to="/authors">Authors</Link>
                                        </li>
                                        <li>
                                            <Link to="/about">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 pb-5 pb-lg-0">
                                <div className="widget mb-2">
                                    <h4 className="widget-title mb-1 pb-1">
                                        POLICY
                                    </h4>
                                    <ul className="contact-info footer-links m-b-4">
                                        <li>
                                            <Link to="/delivery-information">
                                                Delivery Information
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms-conditions">
                                                Terms & Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cancellation-refund-policy">
                                                Cancellation & Refund Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 pb-5 pb-lg-0">
                                <div className="widget mb-2">
                                    <h4 className="widget-title mb-1 pb-1">
                                        Reach Us
                                    </h4>
                                    <ul className="contact-info m-b-4">
                                        <li>
                                            Saraswati Market, Jaswant Road,
                                            Panbazar Gauhati, Assam, India
                                            781001
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget mb-2">
                                    <h4 className="widget-title">Call Us</h4>

                                    <ul class="contact-info m-b-4">
                                        <li>
                                            <a href="tel:919864122631">
                                                +91 9864122631
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget">
                                    {/* <h4 className="widget-title">Follow Us</h4> */}
                                    <div className="social-icons">
                                        <a
                                            href="#0"
                                            className="social-icon social-facebook icon-facebook"
                                            target="_blank"
                                            title="Facebook"
                                        ></a>
                                        <a
                                            href="#0"
                                            className="social-icon social-twitter icon-twitter"
                                            target="_blank"
                                            title="Twitter"
                                        ></a>
                                        <a
                                            href="#0"
                                            className="social-icon social-linkedin fab fa-linkedin-in"
                                            target="_blank"
                                            title="Linkedin"
                                        ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container d-flex justify-content-between align-items-center flex-wrap">
                        <p className="footer-copyright py-3 pr-4 mb-0">
                            &copy; {projectName}. All Rights Reserved{" "}
                            {new Date().getFullYear()}
                        </p>

                        <p>
                            Design &amp; Developed By{" "}
                            <a
                                style={{ color: "#f2f2f2" }}
                                href="https://nakhyatra.com"
                                target="_blank"
                            >
                                Nakhyatra Technologies
                            </a>
                        </p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
