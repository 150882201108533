import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Form, Formik, Field} from 'formik'
import { connect } from 'react-redux'

import Breadcrumb from '../../includes/breadcrumb/Breadcrumb'
import { scrollToTop } from '../../includes/commonFunctions'
import LoginSchema from './validation'
import ErrorMsg from '../../includes/form-inputs/ErrorMsg'
import { userLogin } from '../../../actions'
import LoadingBtn from '../../includes/buttons/LoadingBtn'
import {staticURL} from '../../config'

class Login extends Component {

    componentDidMount(){
        scrollToTop()
    }

    submitLoginFrom = (values, actions) => {
        this.props.userLogin(values,actions)
    }

    textInput = ({ name, label, placeholder, type, classnames, field }) => {
        const inputClassnames = classnames ? classnames : `mb-1`;
        return (
            <>
                <label>{label}</label>
                <input type={type} name={name} className={`form-control ${inputClassnames}`} placeholder={placeholder} {...field} />
            </>
        )
    }

    render() {

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'Login',
                url: null,
                active: true
            }
        ]

        const { isLoggedIn, isLoginLoading, isSubmitting } = this.props

        if(isLoggedIn && !isLoginLoading){
            window.location.href = staticURL;
        }

        return (
            <main className="main">
			
                <Breadcrumb data={breadcrumbData} />

                <div className="about-section form001 bg001">
                    <div className="container ">
                        <div className="row ">
                            <div className="col-md-6 offset-md-3 bg-white p-0">
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: ''
                                    }}
                                    onSubmit={(values, actions) => {
                                        this.submitLoginFrom(values, actions);
                                    }}
                                    enableReinitialize = {true}
                                    validationSchema={LoginSchema}
                                >{(props: FormikProps<any>) => (
                                    <Form className="border border-light p-5 box001 mb-0">

                                        <ul className="nav nav-tabs mb-2" role="tablist">
                                            <li className="nav-item">
                                                <a href="#0" className="nav-link active">Log In</a>
                                            </li>
                                        </ul>

                                        <Field component={this.textInput} label="Email" name="email" placeholder="Email" type="email"></Field>
                                        {props.errors.email && props.touched.email && <ErrorMsg msg={props.errors.email} />}

                                        <Field component={this.textInput} label="Password" name="password" placeholder="Password" type="password"></Field>
                                        {props.errors.password && props.touched.password && <ErrorMsg msg={props.errors.password} />}

                                        <div className="d-flex justify-content-around">
                                            
                                            <div>
                                                <Link to="/forgot-password">Forgot password?</Link>
                                            </div>
                                        </div>

                                        <button className={`btn btn-block my-4`} disabled={ isSubmitting ? true : false } type="submit"> { isSubmitting ? <LoadingBtn /> : 'Sign in' }</button>

                                        <p>Don't have an account? 
                                            <Link to="/register"> Create your account</Link>
                                        </p>   

                                    </Form>
                                )}
                                </Formik>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.userData.login,
        isLoginLoading: state.userData.loginLoading,
        isSubmitting: state.commonFunctions.isSubmitting
    }
}

export default connect(mapStateToProps, { userLogin })(Login)