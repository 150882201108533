import React, { Component } from "react"
import Slider from "react-slick"
import { connect } from "react-redux"

import { getBestsellerBooks } from "../../../actions"
import ProductSingle from "../../product-listings/product-single/ProductSingle"
import { LeftArrow, RightArrow } from "../../includes/buttons/CustomArrows"

class BestsellerProducts extends Component {
    componentDidMount() {
        this.props.getBestsellerBooks(8)
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 1000,
            slidesToShow: 6,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: <LeftArrow />,
            prevArrow: <RightArrow />,
            autoplay: false,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        }

        const { loading, data } = this.props.bestsellerProducts
        const { myWishlistItems } = this.props.userData

        const featured_products =
            !loading && data.length > 0
                ? data.map((product) => {
                      const liked_by_user = myWishlistItems.includes(product.id)
                          ? true
                          : false
                      return (
                          <div className="box002 px-2" key={product.id}>
                              <ProductSingle
                                  name={product.name}
                                  slug={product.slug}
                                  category={product.main_category.name}
                                  category_slug={`/category/${product.main_category.slug}`}
                                  retail_price={product.retail_price}
                                  selling_price={product.selling_price}
                                  image={
                                      product.product_image
                                          ? product.product_image.api_image
                                          : null
                                  }
                                  is_favourite={liked_by_user}
                                  pid={product.id}
                              />
                          </div>
                      )
                  })
                : null

        return data.length > 0 ? (
            <div className=" pt-1 container box001">
                <ul className="nav nav-tabs mb-2" role="tablist">
                    <li className="nav-item">
                        <a href="#0" className="nav-link active">
                            Best Sellers
                        </a>
                    </li>
                </ul>
                <div className="latest-products">
                    <Slider {...settings} className="main-slide books-sections">
                        {featured_products}
                    </Slider>
                </div>
            </div>
        ) : null
    }
}

const mapStateToProps = (state) => {
    return {
        bestsellerProducts: state.homePage.bestsellerProducts,
        userData: state.userData,
    }
}

export default connect(mapStateToProps, { getBestsellerBooks })(
    BestsellerProducts
)
