import React, { Component } from "react"
import Slider from "react-slick"
import { connect } from "react-redux"

import ProductSingle from "../product-listings/product-single/ProductSingle"
import { LeftArrow, RightArrow } from "../includes/buttons/CustomArrows"
class RelatedProducts extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 1000,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            arrows: true,
            nextArrow: <LeftArrow />,
            prevArrow: <RightArrow />,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        }

        const { myWishlistItems } = this.props.userData

        const related_products = this.props.relatedProducts
        const related_products_list =
            related_products.length > 0
                ? related_products.map((product) => {
                      const liked_by_user = myWishlistItems.includes(product.id)
                          ? true
                          : false
                      return (
                          <div className="box002 px-2" key={product.id}>
                              <ProductSingle
                                  name={product.name}
                                  slug={product.slug}
                                  category={product.main_category.name}
                                  category_slug={`/category/${product.main_category.slug}`}
                                  retail_price={product.retail_price}
                                  selling_price={product.selling_price}
                                  image={
                                      product.product_image
                                          ? product.product_image.api_image
                                          : null
                                  }
                                  is_favourite={liked_by_user}
                                  pid={product.id}
                              />
                          </div>
                      )
                  })
                : null

        return (
            <div class="col-lg-12 col-sm-12">
                <div class="products-section pt-0">
                    <h2 class="section-title">Related Products</h2>
                    <div className="box001">
                        <Slider
                            {...settings}
                            className="main-slide books-sections"
                        >
                            {related_products_list}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        relatedProducts: state.productDetailsData.related_products,
        userData: state.userData,
    }
}

export default connect(mapStateToProps, {})(RelatedProducts)
