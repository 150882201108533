import React, { Component } from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { getAuthorsList } from "../../actions"
import { scrollToTop } from "../includes/commonFunctions"
import SingleAuthor from "./single-author/SingleAuthor"
import { projectName } from "../config"
import "./author-list.css"

class AuthorsList extends Component {
    componentDidMount() {
        scrollToTop()
        this.props.getAuthorsList(20, this.props.authorData.current_page)
    }

    render() {
        const { authors, current_page, total, per_page, last_page } =
            this.props.authorData

        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Authors",
                url: null,
                active: true,
            },
        ]

        const authorsList =
            authors && authors.length > 0
                ? authors.map((author) => {
                      return (
                          <SingleAuthor
                              key={author.id}
                              name={author.name}
                              image={author.api_image}
                              slug={author.slug}
                          />
                      )
                  })
                : null

        return (
            <>
                <Helmet>
                    <title>{`Authors | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container author-list my-5">
                        <div className="row">{authorsList}</div>
                        {last_page > current_page ? (
                            <div className="text-center mt-3">
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                        this.props.getAuthorsList(
                                            20,
                                            current_page + 1
                                        )
                                    }
                                >
                                    Load More
                                </button>
                            </div>
                        ) : null}
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authorData: state.authorData,
    }
}

export default connect(mapStateToProps, { getAuthorsList })(AuthorsList)
