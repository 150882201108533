import React from "react"
import { Link } from "react-router-dom"

const CartSummary = ({ cart_total }) => {
    let delivery = cart_total > 499 ? 0 : 40

    return (
        <div className="cart-summary">
            <h3>Summary</h3>
            <table className="table table-totals">
                <tbody>
                    <tr>
                        <td>Subtotal</td>
                        <td>₹{cart_total}</td>
                    </tr>
                    <tr>
                        <td>Delivery</td>
                        <td>₹{delivery}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>Order Total</td>
                        <td>₹{cart_total + delivery}</td>
                    </tr>
                </tfoot>
            </table>
            <div className="checkout-methods">
                <Link
                    to="/checkout/addresses"
                    className="btn btn-block btn-sm btn-primary"
                >
                    Continue to Checkout
                </Link>
            </div>
        </div>
    )
}

export default CartSummary
