import React, { Component } from 'react'
import { connect } from 'react-redux'
import Slider from "react-slick"

import {getFeaturedAuthors} from '../../../actions' 
import AuthorItem from './AuthorItem'
import { LeftArrow, RightArrow } from '../../includes/buttons/CustomArrows'

class FeaturedAuthors extends Component {

    componentDidMount(){
        this.props.getFeaturedAuthors(8)
    }

    render() {

        const settings = {
            dots: false,
            infinite: false,
            speed: 1000,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            nextArrow: <LeftArrow />,
            prevArrow: <RightArrow />,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                  }
                }
            ]
        }

        const { loading, data } = this.props.featuredAuthors

        const authors = !loading && data.length > 0 ? data.map( author => {
            return (
                <AuthorItem key={author.id} name={author.name} image={author.api_image} slug={author.slug} />
            )
        }) : null
 
        return (
            <div className="products-section container pt-0 box001">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="nav nav-tabs mb-2" role="tablist">
                            <li className="nav-item">
                                <a href="#0" className="nav-link active">Featured Authors</a>
                            </li>
                        </ul>

                        <div className="">
                            <Slider {...settings} className='main-slide'>
                                {authors}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        featuredAuthors: state.homePage.featuredAuthors
    }
}

export default connect(mapStateToProps, { getFeaturedAuthors })(FeaturedAuthors)
