import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import CheckoutProgressBar from "./CheckoutProgressBar"
import { staticURL } from "../config"
import OrderSummary from "./OrderSummary"
import {
    getMyAddresses,
    selectAddress,
    placeOrder,
    selectFastDelivery,
} from "../../actions"
//import PaymentMethods from "./PaymentMethods"
import LoadingBtn from "../includes/buttons/LoadingBtn"

class Payment extends Component {
    componentDidMount() {
        this.props.getMyAddresses()
        this.props.selectFastDelivery(false)
        window.scrollTo(0, 0)
    }

    selectAddress = (id) => {
        //remove selected from any other address
        const elements = document.querySelectorAll(".shipping-address-box")
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index]
            element.classList.contains("active") &&
                element.classList.remove("active")
        }
        //make the address selected
        document.querySelector(`#address-item-${id}`).classList.add("active")

        this.props.selectAddress(id)
    }

    isFastDelivery = () => {
        const fast_delivery_input = document.querySelector("#customCheck")
        if (fast_delivery_input.checked === true) {
            this.props.selectFastDelivery(true)
        } else {
            this.props.selectFastDelivery(false)
        }
    }

    placeOrder = (paymentMode) => {
        this.props.placeOrder(
            this.props.match.params.id,
            paymentMode,
            this.props.userData.fast_delivery
        )
    }

    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Cart",
                url: "/cart",
                active: false,
            },
            {
                id: "3",
                title: "Checkout",
                url: null,
                active: true,
            },
        ]

        const {
            login,
            loginLoading,
            cartItems,
            addresses,
            paymentMode,
            orderPlaced,
            orderId,
            fast_delivery,
        } = this.props.userData
        const isSubmitting = this.props.isSubmitting

        if (!loginLoading && !login) {
            window.location.href = `${staticURL}login`
        }

        if (orderPlaced && paymentMode === "CASH") {
            window.location.href = `${staticURL}order-placed/${orderId}`
        }

        const selectedAddress = addresses.find(
            ({ id }) => parseInt(this.props.match.params.id) === id
        )

        return (
            <main className="main">
                <Breadcrumb data={breadcrumbData} />

                <div className="container my-5 cart-align">
                    <div className="text-center">
                        <CheckoutProgressBar step="1" />
                    </div>
                    <div className="row box001">
                        <div className="col-lg-8 mt-3">
                            {/* Display Selected Address */}
                            <div className="checkout-info-box ">
                                <h3 className="step-title">
                                    Ship To:
                                    <Link
                                        to="/checkout/addresses"
                                        title="Edit"
                                        className="btn btn-sm btn-outline-secondary float-right"
                                    >
                                        <span className="sr-only">Edit</span>
                                        <i className="icon-pencil"></i>
                                        &emsp;Change Address
                                    </Link>
                                </h3>

                                {selectedAddress ? (
                                    <address>
                                        {selectedAddress.name} <br />
                                        {selectedAddress.address_line_1},{" "}
                                        {selectedAddress.address_line_2} <br />
                                        {selectedAddress.city} <br />
                                        {selectedAddress.state},{" "}
                                        {selectedAddress.pincode} <br />
                                        {selectedAddress.mobile}
                                    </address>
                                ) : null}
                            </div>
                            <div className="checkout-info-box ">
                                {/* <h3 className="step-title">Payment Method:</h3>

                                <PaymentMethods /> */}

                                <div class="custom-control custom-checkbox mb-3">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck"
                                        name="fast_delivery"
                                        onClick={() => this.isFastDelivery()}
                                    />
                                    <label
                                        class="custom-control-label mt-0 pt-0"
                                        style={{ fontSize: "1.4rem" }}
                                        for="customCheck"
                                    >
                                        Fast Delivery
                                    </label>
                                </div>

                                <div className="d-flex flex-row-reverse">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            this.placeOrder(paymentMode)
                                        }
                                        disabled={
                                            paymentMode == null || isSubmitting
                                                ? true
                                                : false
                                        }
                                        className="btn btn-block btn-sm btn-primary"
                                    >
                                        {isSubmitting ? (
                                            <LoadingBtn />
                                        ) : (
                                            "Place Order"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <OrderSummary
                                cartItems={cartItems}
                                fastDelivery={fast_delivery}
                            />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        isSubmitting: state.commonFunctions.isSubmitting,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getMyAddresses,
        selectAddress,
        placeOrder,
        selectFastDelivery,
    })(Payment)
)
