import React from "react"

import "./progressbar.css"

const ProgressBar = ({ step }) => {
    return (
        <section className="multi_step_form">
            <form id="msform">
                {parseInt(step) === 5 ? (
                    <ul id="progressbar" className="cancelled">
                        <li className="active">Cancelled</li>
                    </ul>
                ) : (
                    <ul id="progressbar">
                        <li className={parseInt(step) >= 1 ? `active` : null}>
                            Order Placed
                        </li>
                        <li className={parseInt(step) >= 2 ? `active` : null}>
                            Accepted
                        </li>
                        <li className={parseInt(step) >= 3 ? `active` : null}>
                            Shipped
                        </li>
                        <li className={parseInt(step) === 4 ? `active` : null}>
                            Delivered
                        </li>
                    </ul>
                )}
            </form>
        </section>
    )
}

export default ProgressBar
