import React from 'react'
import { ToastContainer } from 'react-toastify'
import './toast-custom-notification.css'

export default function ToastNotification() {
    return (
        <div className='col-md-12' style={{ padding: '2rem', margin: '2rem' }}>
            <ToastContainer theme="dark" />
        </div>
    )
}
