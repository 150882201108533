import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'

import { getMyCart } from '../../actions'
import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import DynamicError from '../includes/errors/DynamicError'
import ToastNotification from '../includes/notifications/ToastNotification'
import CartItem from './CartItem'
import CartSummary from './CartSummary'

class MyCart extends Component {

    componentDidMount(){
        this.props.getMyCart()
    }

    render() {

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '3',
                title: 'Cart',
                url: null,
                active: true
            }
        ]

        const { login, loginLoading, cartItems } = this.props.userData

        let cart_total = 0

        const cart_items = cartItems && cartItems.length > 0 && cartItems.map(item => {

            cart_total += item.total_amount

            return(
                <CartItem pid={item.product_id} image={item.product.product_image.api_image} title={item.product.name} qty={item.quantity} price={item.price} total={item.total_amount} />
            )
        })

        return (
            <main className="main">
                <Breadcrumb data={breadcrumbData} />

                <div className="container my-5 cart-align">
                    <div className="row box001">
                        <div className='col-md-12'>
                            {
                                !login && !loginLoading && (
                                    <DynamicError image="assets/custom/login.png" title="Please login to see your cart items." url="/login" btnText="Go to Login" />
                                )
                            }
                        </div>
                        {
                            login && !loginLoading && cartItems.length > 0 ? (
                                <>
                                    <div className='col-md-8'>
                                        {cart_items}
                                    </div>
                                    <div className='col-md-4'>
                                        <CartSummary cart_total={cart_total} />
                                    </div>  
                                </>
                            ) : login && !loginLoading && cartItems.length === 0 && (
                                <div className='col-md-12'>
                                    <DynamicError image="assets/custom/empty.png" title="You don't have any items in your cart." url="/" btnText="Lets Add One" />
                                </div>
                            )
                        }
                    </div>
                </div>

                <ToastNotification />

            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userData
    }
}

export default connect(mapStateToProps, { getMyCart })(MyCart)
