import React, { Component } from 'react'
import {Helmet} from "react-helmet";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import DynamicError from '../includes/errors/DynamicError'
import Sidebar from './Sidebar'
import { getMyOrders } from '../../actions'
import './my-account.css'
import { baseURL } from '../config';

class MyAccount extends Component {

    componentDidMount(){
        this.props.getMyOrders(10)
    }

    render() {

        const { orders, current_page, total, per_page, last_page } = this.props.userData

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'My Account',
                url: null,
                active: true
            }
        ]

        return (
            <>
                <Helmet>
                    <title>My Account</title>
                </Helmet>

                <main className="main">
                    <Breadcrumb data={breadcrumbData} />

                    <div className="container my-5">
                        <div className="row">
                            <div className="col-lg-9 order-lg-last dashboard-content">
                                {
                                    orders && orders.length > 0 ? (
                                        orders.map(order => {
                                            return (
                                                <div className="card" key={`order-${order.order_no}`}>
                                                    <div className="card-header">
                                                        Order #{order.order_no}
                                                        <Link to={`order-details/${order.order_no}`} className="card-edit">View Details</Link>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            order.products.map(item => {
                                                                return (
                                                                    <div className="product-default left-details product-widget" key={`order-item-${item.product.id}`}>
                                                                        <figure>
                                                                            <Link to={`product/${item.product.slug}`}>
                                                                                <img src={`${baseURL}${item.product.product_image.api_image}`} alt={item.product.name} />
                                                                            </Link>
                                                                        </figure>
                                                                        <div className="product-details">
                                                                            <h2 className="product-title">
                                                                                <Link to={`product/${item.product.slug}`}>{item.product.name}</Link>
                                                                            </h2>
                                                                            <div className="">
                                                                                Quantity: {item.quantity}
                                                                            </div>
                                                                            <div className="price-box mt-1">
                                                                                <span className="product-price">₹{item.total_amount}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <DynamicError image="assets/custom/orders.png" title="Your order list is empty." btnText="Go to home" url="/" />
                                    )
                                }
                            </div>
                            <Sidebar page="my-account" />
                        </div>
                    </div>
                </main>
            </>
            
        )
    }
}

const mapStateToProps = state => {
    return{
        userData: state.userData
    }
}

export default connect(mapStateToProps, { getMyOrders })(MyAccount)