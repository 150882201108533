import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { logout } from '../../actions'

class MobileMenu extends Component {

    closeMenuOnClick = () => {
        document.querySelector('body').classList.remove('mmenu-active')
    }

    render() {

        const { loading, categories, languages } = this.props.headerData
        const { login, loginLoading, cartItems } = this.props.userData

        const headerCategories = !loading && categories.length > 0 ? categories.map( category => {
            return (
                <li key={category.id}>
                    <Link to={`/category/${category.slug}`} style={{ cursor: 'pointer' }} title={category.name}>
                        { category.name }
                    </Link>
                    {
                        category.subcategories && category.subcategories.length > 0 ? (
                            <ul>
                                {
                                    category.subcategories.map(subcategory => {
                                        return (
                                            <li key={subcategory.id}>
                                                <Link onClick={() => this.closeMenuOnClick()} to={`/sub-category/${subcategory.slug}`} className="" title={subcategory.name}>
                                                    {subcategory.name}
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        ) : null
                    }
                </li>
            )
        }) : null

        return (
            <>
                <div className="mobile-menu-overlay"></div> {/* End .mobil-menu-overlay */}

                <div className="mobile-menu-container">
                    <div className="mobile-menu-wrapper">
                        <span className="mobile-menu-close"><i className="icon-cancel"></i></span>
                        <nav className="mobile-nav">
                            <ul className="mobile-menu">
                                <li className="">
                                    <Link onClick={() => this.closeMenuOnClick()} to="/">Home</Link>
                                </li>
                                {/* <li>
                                    <a href="product.html">Products</a>
                                    <ul>
                                        <li>
                                            <a href="#0">Variations</a>
                                            <ul>
                                                <li><a href="product.html">Horizontal Thumbs</a></li>
                                                <li><a href="product-full-width.html">Vertical Thumbnails<span className="tip tip-hot">Hot!</span></a></li>
                                                <li><a href="product.html">Inner Zoom</a></li>
                                                <li><a href="product-addcart-sticky.html">Addtocart Sticky</a></li>
                                                <li><a href="product-sidebar-left.html">Accordion Tabs</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li> */}
                                <li>
                                    <a href='#0'>
                                        Categories
                                    </a>
                                    <ul>
                                        {headerCategories}
                                    </ul>
                                </li>
                                <li className="">
                                    <Link onClick={() => this.closeMenuOnClick()} to="/blogs">Blogs</Link>
                                </li>
                                <li className="">
                                    <Link onClick={() => this.closeMenuOnClick()} to="/authors">Authors</Link>
                                </li>
                                <li className="">
                                    <Link onClick={() => this.closeMenuOnClick()} to="/about">About Us</Link>
                                </li>
                                <li className="">
                                    <Link onClick={() => this.closeMenuOnClick()} to="/contact">Contact Us</Link>
                                </li>
                                {
                                    login && !loginLoading ? (
                                        <li className="">
                                            <Link onClick={() => this.closeMenuOnClick()} to="/my-account">My Account</Link>
                                        </li>
                                    ) : null
                                }
                                {
                                    login && !loginLoading ? (
                                        <li className="">
                                            <Link onClick={() => this.closeMenuOnClick()} to="/cart">
                                                Cart
                                                <span className="tip">{cartItems.length}</span>
                                            </Link>
                                        </li>
                                    ) : null
                                }
                                {
                                    login && !loginLoading ? (
                                        <li className="">
                                            <a href="#0" onClick={() => this.props.logout()}>Logout</a>
                                        </li>
                                    ) : (
                                        <li className="">
                                            <Link onClick={() => this.closeMenuOnClick()} to="/login">Log In</Link>
                                        </li>
                                    )
                                }
                                
                            </ul>
                        </nav> {/* End .mobile-nav */}

                        <div className="social-icons">
                            <a href="#0" className="social-icon" rel="noreferrer noopener" target="_blank" ><i className="icon-facebook"></i></a>
                            <a href="#0" className="social-icon" rel="noreferrer noopener" target="_blank" ><i className="icon-twitter"></i></a>
                            <a href="#0" className="social-icon" rel="noreferrer noopener" target="_blank" ><i className="icon-instagram"></i></a>
                        </div> {/* End .social-icons */}
                    </div> {/* End .mobile-menu-wrapper */}
                </div> {/* End .mobile-menu-container */}

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        headerData: state.commonData.header,
        userData: state.userData
    }
}

export default connect(mapStateToProps, {logout})(MobileMenu)