import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import "react-toastify/dist/ReactToastify.min.css"
import { Helmet } from "react-helmet"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import {
    getProductDetails,
    getRelatedProducts,
    showModal,
    addToCart,
} from "../../actions"
import { scrollToTop } from "../includes/commonFunctions"
import { baseURL, projectName } from "../config"
import "./product-details.css"
import RelatedProducts from "./RelatedProducts"
import PageModal from "../includes/modal/PageModal"
import ProductPreview from "./ProductPreview"
import DynamicError from "../includes/errors/DynamicError"
import ToastNotification from "../includes/notifications/ToastNotification"

class ProductDetails extends Component {
    componentDidMount() {
        scrollToTop()
        this.props.getProductDetails(this.props.match.params.slug)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.props.getProductDetails(this.props.match.params.slug)
            scrollToTop()
        }

        if (
            prevProps.productDetailsData.product &&
            prevProps.productDetailsData.product.id !==
                this.props.productDetailsData.product.id
        ) {
            this.props.getRelatedProducts(
                this.props.productDetailsData.product.main_category_id,
                this.props.productDetailsData.product.id,
                5
            )
        }

        if (this.props.userData.addedToCart) {
            // toast.success("Product Added To Cart", {
            //     position: toast.POSITION.BOTTOM_CENTER
            // });
        }

        //console.log(this.props.userData)
    }

    createMarkup = (body) => {
        return { __html: body }
    }

    render() {
        const { product, related_products } = this.props.productDetailsData
        const { modalOpen, modalName } = this.props.modal
        const { login, loginLoading, addedToCart } = this.props.userData

        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Categories",
                url: "/all-categories",
                active: false,
            },
            {
                id: "3",
                title: product ? product.name : null,
                url: null,
                active: true,
            },
        ]

        return (
            <>
                <Helmet>
                    <title>{`${
                        product ? product.name : null
                    } | ${projectName}`}</title>
                    <meta
                        property="og:url"
                        content={`${window.location.href}`}
                    />
                    <meta
                        property="og:title"
                        content={`${product ? product.name : null}`}
                    />
                    <meta
                        property="og:image"
                        content={`${baseURL}${
                            product && product.product_image
                                ? product.product_image.api_image
                                : null
                        }`}
                    />
                    <meta property="og:site_name" content={`${projectName}`} />
                    <meta property="og:type" content="article" />
                    <meta
                        name="twitter:title"
                        content={`${product ? product.name : null}`}
                    />
                    <meta
                        name="twitter:image"
                        content={`${window.location.href}`}
                    />
                    <meta
                        property="twitter:image"
                        content={`${baseURL}${
                            product && product.product_image
                                ? product.product_image.api_image
                                : null
                        }`}
                    />
                    <meta name="twitter:card" content="summary" />
                </Helmet>

                <main className="main">
                    <Breadcrumb data={breadcrumbData} />

                    <div className="container my-5">
                        <div className="row">
                            {product ? (
                                <>
                                    <div className="col-lg-9 col-sm-12 main-content">
                                        <div className="product-single-container product-single-default">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-5 product-single-gallery">
                                                    <div className="product-item">
                                                        <img
                                                            src={`${baseURL}${
                                                                product &&
                                                                product.product_image
                                                                    ? product
                                                                          .product_image
                                                                          .api_image
                                                                    : null
                                                            }`}
                                                            className="product-single-image"
                                                            alt={
                                                                product
                                                                    ? product.name
                                                                    : null
                                                            }
                                                        />
                                                    </div>

                                                    <div className="btn_custom1 row">
                                                        <div className="col-6">
                                                            <a
                                                                href="#0"
                                                                onClick={() =>
                                                                    this.props.showModal(
                                                                        "previewModal"
                                                                    )
                                                                }
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-dark1 add-cart"
                                                                    title="Preview"
                                                                >
                                                                    <i className="fa fa-book"></i>
                                                                    &emsp;
                                                                    Preview{" "}
                                                                </button>
                                                            </a>
                                                        </div>

                                                        <div className="col-6">
                                                            {product &&
                                                            product.qty_available <
                                                                1 ? (
                                                                <a
                                                                    href="#0"
                                                                    disabled="disabled"
                                                                    style={{
                                                                        cursor: "not-allowed",
                                                                    }}
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        disabled="disabled"
                                                                        style={{
                                                                            cursor: "not-allowed",
                                                                        }}
                                                                        className="btn btn-dark add-cart icon-shopping-cart"
                                                                        title="Out Of Stock"
                                                                    >
                                                                        {" "}
                                                                        Out Of
                                                                        Stock{" "}
                                                                    </button>
                                                                </a>
                                                            ) : (
                                                                <a href="#0">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            this.props.addToCart(
                                                                                product
                                                                                    ? product.id
                                                                                    : null
                                                                            )
                                                                        }
                                                                        className="btn btn-dark add-cart icon-shopping-cart"
                                                                        title="Add to Cart"
                                                                    >
                                                                        {" "}
                                                                        Add to
                                                                        Cart{" "}
                                                                    </button>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-8 col-md-7 product-single-details">
                                                    <h1 className="product-title">
                                                        {product
                                                            ? product.name
                                                            : null}
                                                    </h1>

                                                    <div className="ratings-container">
                                                        <div className="product-ratings">
                                                            <span
                                                                className="ratings1"
                                                                style={{
                                                                    width: "50%",
                                                                }}
                                                            ></span>
                                                        </div>

                                                        <a
                                                            href="#"
                                                            className="rating-link"
                                                        >
                                                            (Ratings: 5)
                                                        </a>
                                                    </div>

                                                    <div className="price-box">
                                                        <span className="old-price">
                                                            ₹{" "}
                                                            {product
                                                                ? product.retail_price
                                                                : null}
                                                        </span>
                                                        <span className="product-price">
                                                            ₹{" "}
                                                            {product
                                                                ? product.selling_price
                                                                : null}
                                                        </span>
                                                    </div>

                                                    {product &&
                                                    product.specifications &&
                                                    product.specifications
                                                        .length > 0 ? (
                                                        <>
                                                            <hr className="divider mb-1" />

                                                            <ul className="text-dark product-highlights">
                                                                {product.specifications.map(
                                                                    (
                                                                        specification
                                                                    ) => {
                                                                        return (
                                                                            <li>
                                                                                <i className="fa fa-check-circle"></i>{" "}
                                                                                {
                                                                                    specification.name
                                                                                }{" "}
                                                                                :{" "}
                                                                                {
                                                                                    specification.details
                                                                                }
                                                                            </li>
                                                                        )
                                                                    }
                                                                )}
                                                            </ul>
                                                        </>
                                                    ) : null}

                                                    <hr className="divider" />

                                                    <div className="product-single-share">
                                                        <label className="sr-only">
                                                            Share:
                                                        </label>
                                                        <a
                                                            href="#"
                                                            className="add-wishlist"
                                                            title="Add to Wishlist"
                                                        >
                                                            Add to Wishlist
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-12">
                                        <a href="#0">
                                            <div className="author001">
                                                <p className="bar_bg text-center">
                                                    Author
                                                </p>

                                                {product &&
                                                product.authors &&
                                                product.authors.length > 0
                                                    ? product.authors.map(
                                                          (author) => {
                                                              return (
                                                                  <Link
                                                                      to={`/author/${author.author_id}`}
                                                                      className="d-flex author-wrapper"
                                                                  >
                                                                      <div
                                                                          style={{
                                                                              flex: "0 1 auto",
                                                                              width: "30%",
                                                                          }}
                                                                      >
                                                                          <img
                                                                              src={`${baseURL}${author.author.api_image}`}
                                                                              alt={
                                                                                  author
                                                                                      .author
                                                                                      .name
                                                                              }
                                                                              style={{
                                                                                  borderRadius:
                                                                                      "50%",
                                                                              }}
                                                                          />
                                                                      </div>
                                                                      <div className="author-name">
                                                                          {
                                                                              author
                                                                                  .author
                                                                                  .name
                                                                          }
                                                                      </div>
                                                                  </Link>
                                                              )
                                                          }
                                                      )
                                                    : null}
                                            </div>
                                        </a>

                                        <div className="product-single-share">
                                            <label className="sr-only">
                                                Share:
                                            </label>

                                            <div className="social-icons mr-2">
                                                <a
                                                    href="#"
                                                    className="btn-edit"
                                                    title=" Share With Friends"
                                                >
                                                    {" "}
                                                    Share With Friends{" "}
                                                </a>
                                                <a
                                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                                                        window.location.href
                                                    )}`}
                                                    className="social-icon social-facebook icon-facebook"
                                                    target="_blank"
                                                    title="Facebook"
                                                ></a>
                                                <a
                                                    href={`https://twitter.com/intent/tweet?text=${encodeURI(
                                                        `Check this book from Aank Baak publication : ${window.location.href}`
                                                    )}`}
                                                    className="social-icon social-twitter icon-twitter"
                                                    target="_blank"
                                                    title="Twitter"
                                                ></a>

                                                <a
                                                    href={`whatsapp://send?text=${encodeURI(
                                                        `Check this book from Aank Baak publication : ${window.location.href}`
                                                    )}`}
                                                    className="social-icon social-whatsapp fab fa-whatsapp"
                                                    target="_blank"
                                                    title="Share on whatsapp"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12 main-content">
                                        <div
                                            className="product-single-collapse"
                                            id="productAccordion"
                                        >
                                            <div className="product-collapse-panel">
                                                <h3 className="product-collapse-title">
                                                    <a
                                                        href="#0"
                                                        role="button"
                                                        aria-expanded="true"
                                                        aria-controls="product-collapse-desc"
                                                    >
                                                        Description
                                                    </a>
                                                </h3>

                                                <div
                                                    className="product-collapse-body collapse show"
                                                    id="product-collapse-desc"
                                                    data-parent="#productAccordion"
                                                >
                                                    <div className="collapse-body-wrapper">
                                                        <div
                                                            className="product-desc-content"
                                                            dangerouslySetInnerHTML={this.createMarkup(
                                                                product
                                                                    ? product.description
                                                                    : null
                                                            )}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {related_products &&
                                        related_products.length > 0 && (
                                            <RelatedProducts />
                                        )}

                                    <ToastNotification />
                                </>
                            ) : (
                                <div className="col-12">
                                    <DynamicError
                                        image="assets/custom/404.png"
                                        title="Oops! The page you are looking for is not found."
                                        btnText="Go to home"
                                        url="/"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </main>
                {modalOpen && modalName === "previewModal" && (
                    <PageModal>
                        <ProductPreview
                            images={product ? product.preview_images : []}
                        />
                    </PageModal>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        productDetailsData: state.productDetailsData,
        modal: state.modal,
        userData: state.userData,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getProductDetails,
        getRelatedProducts,
        showModal,
        addToCart,
    })(ProductDetails)
)
