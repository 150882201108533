import React from 'react'
import ImageGallery from 'react-image-gallery'

import { baseURL } from '../config'

const ProductPreview = ({ images }) => {

    let gallery = []

    images.map(image => {
        gallery.push({'original': `${baseURL}${image.api_image}`, 'originalClass': 'preview-modal-wrap'})
    })

    return (
        <div className='preview-modal'>
            <ImageGallery items={gallery} showPlayButton={false} infinite={false} />
        </div>
    )
}

export default ProductPreview
