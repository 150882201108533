import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import Sidebar from "./Sidebar"
import { getOrderDetails } from "../../actions"
import "./my-account.css"
import { baseURL } from "../config"
import ProgressBar from "./progressbar/ProgressBar"

class OrderDetails extends Component {
    componentDidMount() {
        this.props.getOrderDetails(this.props.match.params.id)
    }

    render() {
        const { orderDetails } = this.props.userData

        const {
            order_no,
            payment_mode,
            total_amount,
            status,
            products,
            address,
            order_date,
        } = orderDetails ? orderDetails : {}

        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "My Account",
                url: "/my-account",
                active: false,
            },
            {
                id: "3",
                title: "Order Details",
                url: null,
                active: true,
            },
        ]

        return (
            <>
                <Helmet>
                    <title>Order Details - {this.props.match.params.id}</title>
                </Helmet>

                <main className="main">
                    <Breadcrumb data={breadcrumbData} />

                    <div className="container my-5">
                        <div className="row">
                            <div className="col-lg-9 order-lg-last dashboard-content">
                                <div className="card">
                                    <div className="card-header">
                                        Order Details - {order_no}
                                        <a
                                            href={`${baseURL}orders/download-invoice/${order_no}`}
                                            className="card-edit"
                                            target="_blank"
                                            noopener
                                            norefferer
                                        >
                                            Print Invoice
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <h5>Delivery Address</h5>
                                                <address>
                                                    <span className="font-bold">
                                                        {address &&
                                                            address.name}
                                                    </span>
                                                    <br />
                                                    {address &&
                                                        address.address_line_1}
                                                    ,{" "}
                                                    {address &&
                                                        address.address_line_2}
                                                    <br />
                                                    {address &&
                                                        address.city},{" "}
                                                    {address && address.state} -{" "}
                                                    {address && address.pincode}
                                                    <br />
                                                    {address && address.mobile}
                                                </address>

                                                <h5>Amount / Payment Method</h5>
                                                <div className="mb-2">{`₹${total_amount} / ${
                                                    payment_mode === "CASH"
                                                        ? "COD"
                                                        : "ONLINE"
                                                }`}</div>

                                                <h5>Date</h5>
                                                <div>{`${order_date}`}</div>
                                            </div>
                                            <div className="col-lg-6">
                                                <ProgressBar step={status} />
                                            </div>
                                            <div className="col-12 border-top mt-1 pt-4">
                                                <h5>Ordered Items: </h5>
                                                {products &&
                                                    products.map((item) => {
                                                        return (
                                                            <div
                                                                className="product-default left-details product-widget"
                                                                key={`order-item-${item.product.id}`}
                                                            >
                                                                <figure>
                                                                    <Link
                                                                        to={`/product/${item.product.slug}`}
                                                                    >
                                                                        <img
                                                                            src={`${baseURL}${item.product.product_image.api_image}`}
                                                                            alt={
                                                                                item
                                                                                    .product
                                                                                    .name
                                                                            }
                                                                        />
                                                                    </Link>
                                                                </figure>
                                                                <div className="product-details">
                                                                    <h2 className="product-title">
                                                                        <Link
                                                                            to={`/product/${item.product.slug}`}
                                                                        >
                                                                            {
                                                                                item
                                                                                    .product
                                                                                    .name
                                                                            }
                                                                        </Link>
                                                                    </h2>
                                                                    <div className="">
                                                                        Quantity:{" "}
                                                                        {
                                                                            item.quantity
                                                                        }
                                                                    </div>
                                                                    <div className="price-box mt-1">
                                                                        <span className="product-price">
                                                                            ₹
                                                                            {
                                                                                item.total_amount
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Sidebar page="my-account" />
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
    }
}

export default withRouter(
    connect(mapStateToProps, { getOrderDetails })(OrderDetails)
)
