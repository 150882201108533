import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Breadcrumb from '../../includes/breadcrumb/Breadcrumb'
import { getAuthorDetails } from '../../../actions'
import Error404 from '../../includes/errors/Error404'
import {baseURL} from '../../config'
import './author-details.css'
import ProductSingle from '../../product-listings/product-single/ProductSingle'
import { scrollToTop } from '../../includes/commonFunctions'
import { projectName } from '../../config'

class AuthorDetails extends Component {

    componentDidMount(){
        scrollToTop()
        this.props.getAuthorDetails(this.props.match.params.slug)
    }

    createMarkup = (body) => {
        return {__html: body};
    }

    render() {

        const { loading, error, data } = this.props.authorData
        const { myWishlistItems } = this.props.userData

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'Authors',
                url: '/authors',
                active: false
            },
            {
                id: '3',
                title: data ? data.name : null,
                url: null,
                active: true
            }
        ]

        const author_books = data && data.books && data.books.length > 0 ? data.books.map( book => {
            const liked_by_user = myWishlistItems.includes(book.book.id) ? true : false
            return (
                <>
                    <div className='col-6 col-md-2' key={book.book.id}>
                        <ProductSingle name={book.book.name} slug={book.book.slug} category={book.book.main_category.name} category_slug={`/category/${book.book.main_category.slug}`} retail_price={book.book.retail_price} selling_price={book.book.selling_price} image={book.book.product_image ? book.book.product_image.api_image : null} is_favourite={liked_by_user} pid={book.book.id} />
                    </div>
                </>
                
            )
        }) : null

        return (

            <>
                <Helmet>
                    <title>{data ? `${data.name} | ${projectName} ` : `${projectName}`} </title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        {
                            !loading && error && (
                                <div className="row">
                                    <div className='col-md-12'>
                                        <Error404 />
                                    </div>
                                </div>
                            )
                        }
                        {
                            !loading && !error && data && (
                                <>
                                    <div className="row align-items-lg-center">
                                        <div className="col-md-3 col-12 text-center author-details-img">
                                            <img src={`${baseURL}${data.api_image}`} alt={data.name} style={{ margin: '0 auto' }} />
                                        </div>

                                        <div className="col-md-9 padding-left-lg author-desc">
                                            <h3 className="text-primary">{data.name}</h3>
                                            <div className='author-bio' dangerouslySetInnerHTML={this.createMarkup(data.bio)}></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="box001 col-md-12 mt-5">
                                            <ul className="nav nav-tabs mb-2" role="tablist">
                                                <li className="nav-item">
                                                    <a href="#0" className="nav-link active">Books by {data.name}</a>
                                                </li>
                                            </ul>
                                            <div className="row box002">
                                                {author_books}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        authorData: state.authorData.author_details,
        userData: state.userData
    }
}

export default withRouter(connect(mapStateToProps, { getAuthorDetails })(AuthorDetails))
