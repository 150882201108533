import React, { Component } from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { withRouter } from "react-router-dom"

import { baseURL, projectName, staticURL } from "../config"
import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { getBlogDetails } from "../../actions"
import { scrollToTop } from "../includes/commonFunctions"

class BlogDetails extends Component {
    componentDidMount() {
        scrollToTop()
        this.props.getBlogDetails(this.props.match.params.slug)

        var disqus_config = function () {
            this.page.url = window.location.href // Replace PAGE_URL with your page's canonical URL variable
            this.page.identifier = `blog-details/${this.props.match.params.slug}` // Replace PAGE_IDENTIFIER with your page's unique identifier variable
        }

        ;(function () {
            // DON'T EDIT BELOW THIS LINE
            var d = document,
                s = d.createElement("script")
            s.src = "https://https-aankbaak-com.disqus.com/embed.js"
            s.setAttribute("data-timestamp", +new Date())
            ;(d.head || d.body).appendChild(s)
        })()
    }

    createMarkup = (body) => {
        return { __html: body }
    }

    render() {
        const { data } = this.props.blogData

        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "Blogs",
                url: "/blogs",
                active: false,
            },
            {
                id: "3",
                title: data ? data.title : null,
                url: null,
                active: true,
            },
        ]

        const encodedURL = encodeURI(window.location.href)

        return (
            <>
                <Helmet>
                    <title>{`${
                        data ? data.title : null
                    } | ${projectName}`}</title>
                    <meta property="og:title" content={data.title} />
                    <meta
                        property="og:description"
                        content="By Aank Baak Publication"
                    />
                    <meta
                        property="og:image"
                        content={`${baseURL}${data.api_large_image}`}
                    />
                    <meta property="og:url" content={encodedURL} />
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container author-list my-5">
                        <div className="row">
                            <div className="col-md-12">
                                <article className="post single">
                                    <div className="post-media">
                                        <img
                                            src={`${baseURL}${data.api_large_image}`}
                                            alt={data.title}
                                        />
                                    </div>

                                    <div
                                        className="post-body"
                                        style={{ borderBottom: "none" }}
                                    >
                                        <div className="post-date">
                                            <span className="day">
                                                {data.day}
                                            </span>
                                            <span className="month">
                                                {data.month}
                                            </span>
                                        </div>

                                        <h2 className="post-title">
                                            {data.title}
                                        </h2>

                                        <div className="post-meta">
                                            <span>
                                                <i className="icon-calendar"></i>
                                                {data.full_date}
                                            </span>
                                            <span>
                                                <i className="icon-user"></i>By{" "}
                                                <a href="#0">Aank Baak</a>
                                            </span>
                                        </div>

                                        <div
                                            className="post-content"
                                            dangerouslySetInnerHTML={this.createMarkup(
                                                data.body
                                            )}
                                        ></div>

                                        <div className="post-share">
                                            <h3>
                                                <i className="icon-forward"></i>
                                                Share it on
                                            </h3>

                                            <div
                                                className="social-icons"
                                                style={{ color: "#FFFFFF" }}
                                            >
                                                <a
                                                    rel="noreferrer"
                                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`}
                                                    className="social-icon social-facebook"
                                                    target="_blank"
                                                    title="Facebook"
                                                >
                                                    <i className="icon-facebook"></i>
                                                </a>
                                                <a
                                                    rel="noreferrer"
                                                    href={`https://twitter.com/intent/tweet?via=aankbaak&text=${data.title}&url=${encodedURL}`}
                                                    className="social-icon social-twitter"
                                                    target="_blank"
                                                    title="Twitter"
                                                >
                                                    <i className="icon-twitter"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </article>

                                <div id="disqus_thread"></div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        blogData: state.blogData.blog_details,
    }
}

export default withRouter(
    connect(mapStateToProps, { getBlogDetails })(BlogDetails)
)
