import React, { Component } from 'react'
import { connect } from 'react-redux'

import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import DynamicError from '../includes/errors/DynamicError'
import { getMyWishlist } from '../../actions'
import ProductSingle from '../product-listings/product-single/ProductSingle'

class MyWishlist extends Component {

    componentDidMount(){
        this.props.getMyWishlist(18)
    }

    componentDidUpdate(prevProps){
        if(prevProps.userData.myWishlistItems.length !== this.props.userData.myWishlistItems.length){
            this.props.getMyWishlist(18)
        }
    }

    render() {

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '3',
                title: 'My Wishlist',
                url: null,
                active: true
            }
        ]

        const { login, loginLoading, myWishlist, myWishlistItems } = this.props.userData

        const wishlist_items = myWishlist && myWishlist.length > 0 ? myWishlist.map(item => {
            const liked_by_user = myWishlistItems.includes(item.product.id) ? true : false                 
            return (
                <div className='col-lg-2 col-md-3 col-sm-4 col-6 box002'>
                    <ProductSingle name={item.product.name} slug={item.product.slug} category={item.product.main_category.name} category_slug={`/category/${item.product.main_category.slug}`} retail_price={item.product.retail_price} selling_price={item.product.selling_price} image={item.product.product_image ? item.product.product_image.api_image : null} is_favourite={liked_by_user} pid={item.product.id} />
                </div>
            )
        
        }) : null

        return (
            <main className="main">
                <Breadcrumb data={breadcrumbData} />

                <div className="container my-5">
                    <div className="row box001">
                        <div className='col-md-12'>
                            {
                                !login && !loginLoading && (
                                    <DynamicError image="assets/custom/login.png" title="Please login to see your wishlist items." url="/login" btnText="Go to Login" />
                                )
                            }
                        </div>
                        {
                                login && !loginLoading && myWishlist.length > 0 ? wishlist_items : login && !loginLoading && myWishlist.length === 0 && (
                                    <div className='col-md-12'>
                                        <DynamicError image="assets/custom/wishlist.png" title="You don't have any items in your wishlist." url="/" btnText="Lets Go Find One" />
                                    </div>
                                )
                            }
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userData
    }
}

export default connect(mapStateToProps, { getMyWishlist })(MyWishlist)