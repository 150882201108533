import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Form, Formik, Field} from 'formik'
import { connect } from 'react-redux'

import Breadcrumb from '../../includes/breadcrumb/Breadcrumb'
import { scrollToTop } from '../../includes/commonFunctions'
import RegisterSchema from './validation'
import ErrorMsg from '../../includes/form-inputs/ErrorMsg'
import { userRegister } from '../../../actions'
import LoadingBtn from '../../includes/buttons/LoadingBtn'
import { baseURL, staticURL } from '../../config'

class Register extends Component {

    componentDidMount(){
        scrollToTop()
    }

    showModal = (name) => {
        this.props.showModal(name);
    };

    submitRegisterFrom = (values, actions) => {
        this.props.userRegister(values,actions)
    }

    textInput = ({ name, label, placeholder, type, classnames, field }) => {
        const inputClassnames = classnames ? classnames : `mb-1`;
        return (
            <>
                <label>{label}</label>
                <input type={type} name={name} className={`form-control ${inputClassnames}`} placeholder={placeholder} {...field} />
            </>
        )
    }

    render() {

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'Register',
                url: null,
                active: true
            }
        ]

        const { isSubmitting } = this.props
        const { register } = this.props.user

        return (
            <main className="main">
			
                <Breadcrumb data={breadcrumbData} />

                <div className="about-section form001 bg001">
                    <div className="container ">
                        <div className="row ">

                            {
                                register ? (
                                    <div className="col-md-6 offset-md-3 bg-white p-5">
                                        <div className='text-center'>
                                            <img src={`${staticURL}assets/custom/success.gif`} alt="success" style={{ width: '100px', margin: '0 auto', marginBottom: '2rem' }} />
                                            <h3>Registration successfull. Please login to your account.</h3>
                                            <Link to="/login" className='btn btn-sm btn-dark'>Login</Link>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-md-6 offset-md-3 bg-white p-0">

                                        <Formik
                                                initialValues={{
                                                    fname: '',
                                                    lname: '',
                                                    email: '',
                                                    password: '',
                                                    password_confirmation: '',
                                                    mobile: ''
                                                }}
                                                onSubmit={(values, actions) => {
                                                    this.submitRegisterFrom(values, actions);
                                                }}
                                                enableReinitialize = {true}
                                                validationSchema={RegisterSchema}
                                            >{(props: FormikProps<any>) => (
                                            <Form className="border border-light p-5 box001 mb-0">

                                                <ul className="nav nav-tabs mb-2" role="tablist">
                                                    <li className="nav-item">
                                                        <a href="#0" className="nav-link active">Create Your Account</a>
                                                    </li>
                                                </ul>

                                                <Field component={this.textInput} label="First Name" name="fname" placeholder="First Name" type="text"></Field>
                                                {props.errors.fname && props.touched.fname && <ErrorMsg msg={props.errors.fname} />}

                                                <Field component={this.textInput} label="Last Name" name="lname" placeholder="Last Name" type="text"></Field>
                                                {props.errors.lname && props.touched.lname && <ErrorMsg msg={props.errors.lname} />}

                                                <Field component={this.textInput} label="Email" name="email" placeholder="Email" type="email"></Field>
                                                {props.errors.email && props.touched.email && <ErrorMsg msg={props.errors.email} />}

                                                <Field component={this.textInput} label="Mobile No" name="mobile" placeholder="Mobile No" type="number"></Field>
                                                {props.errors.mobile && props.touched.mobile && <ErrorMsg msg={props.errors.mobile} />}

                                                <Field component={this.textInput} label="Password" name="password" placeholder="Password" type="password"></Field>
                                                {props.errors.password && props.touched.password && <ErrorMsg msg={props.errors.password} />}

                                                <Field component={this.textInput} label="Confirm Password" name="password_confirmation" placeholder="Confirm Password" type="password"></Field>
                                                {props.errors.password_confirmation && props.touched.password_confirmation && <ErrorMsg msg={props.errors.password_confirmation} />}

                                                <button className={`btn btn-block my-4`} disabled={ isSubmitting ? true : false } type="submit"> { isSubmitting ? <LoadingBtn /> : 'Register' }</button>

                                                <p>
                                                    Already have an account? 
                                                    <Link to="/login"> Log In</Link>
                                                </p> 
                                            </Form>
                                            )}
                                        </Formik>
                                        
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSubmitting: state.commonFunctions.isSubmitting,
        user: state.userData
    }
}

export default connect(mapStateToProps, { userRegister })(Register)