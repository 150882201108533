import React, { Component } from "react"
import { Form, Formik, Field } from "formik"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { staticURL } from "../config"
import ErrorMsg from "../includes/form-inputs/ErrorMsg"
import LoadingBtn from "../includes/buttons/LoadingBtn"
import AddressSchema from "./AddressValidation"
import { addNewAddress } from "../../actions"
import ToastNotification from "../includes/notifications/ToastNotification"

class AddNewAddress extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    submitAddressFrom = (values, actions) => {
        this.props.addNewAddress(values, actions)
    }

    textInput = ({ name, label, placeholder, type, classnames, field }) => {
        const inputClassnames = classnames ? classnames : `mb-1`
        return (
            <>
                <label>{label}</label>
                <input
                    type={type}
                    name={name}
                    className={`form-control ${inputClassnames}`}
                    placeholder={placeholder}
                    {...field}
                />
            </>
        )
    }

    goBackButton = () => {
        const param = this.getQueryParams(this.props.location.search, "p")
        if (param) {
            this.props.history.push(`/${param}`)
            //window.location.href = `${staticURL}${param}`
        } else {
            this.props.history.push("/my-addresses")
        }
    }

    redirectToCheckout = () => {
        const param = this.getQueryParams(this.props.location.search, "p")
        if (param) {
            window.location.href = `${staticURL}${param}`
        } else {
            this.props.history.push("/my-addresses")
        }
    }

    getQueryParams = (searchParams, term) => {
        const queryParams = new URLSearchParams(searchParams)
        return queryParams.get(term)
    }

    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "My Account",
                url: "/my-account",
                active: false,
            },
            {
                id: "3",
                title: "Add New Address",
                url: null,
                active: true,
            },
        ]

        const { login, loginLoading, addressAction } = this.props.userData
        const isSubmitting = this.props.isSubmitting

        if (!login && !loginLoading) {
            window.location.href = `${staticURL}login`
        }

        if (addressAction) {
            this.redirectToCheckout()
        }

        return (
            <main className="main">
                <Breadcrumb data={breadcrumbData} />

                <div className="about-section form001 bg001">
                    <div className="container ">
                        <div className="row ">
                            <div className="col-md-8 offset-md-2 bg-white p-0">
                                <Formik
                                    initialValues={{
                                        name: "",
                                        mobile: "",
                                        address_line_1: "",
                                        address_line_2: "",
                                        city: "",
                                        state: "",
                                        pincode: "",
                                    }}
                                    onSubmit={(values, actions) => {
                                        this.submitAddressFrom(values, actions)
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={AddressSchema}
                                >
                                    {(props: FormikProps<any>) => (
                                        <Form className="border border-light p-5 box001 mb-0">
                                            <ul
                                                className="nav nav-tabs mb-2"
                                                role="tablist"
                                            >
                                                <li className="nav-item">
                                                    <a
                                                        href="#0"
                                                        className="nav-link active"
                                                    >
                                                        Add New Address
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Field
                                                        component={
                                                            this.textInput
                                                        }
                                                        label="Name"
                                                        name="name"
                                                        placeholder="Name"
                                                        type="text"
                                                    ></Field>
                                                    {props.errors.name &&
                                                        props.touched.name && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .name
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="col-md-6">
                                                    <Field
                                                        component={
                                                            this.textInput
                                                        }
                                                        label="Mobile"
                                                        name="mobile"
                                                        placeholder="Mobile"
                                                        type="number"
                                                    ></Field>
                                                    {props.errors.mobile &&
                                                        props.touched
                                                            .mobile && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .mobile
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="col-md-12">
                                                    <Field
                                                        component={
                                                            this.textInput
                                                        }
                                                        label="Address Line 1"
                                                        name="address_line_1"
                                                        placeholder="Address Line 1"
                                                        type="text"
                                                    ></Field>
                                                    {props.errors
                                                        .address_line_1 &&
                                                        props.touched
                                                            .address_line_1 && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .address_line_1
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="col-md-12">
                                                    <Field
                                                        component={
                                                            this.textInput
                                                        }
                                                        label="Address Line 2 (Optional)"
                                                        name="address_line_2"
                                                        placeholder="Address Line 2 (Optional)"
                                                        type="text"
                                                    ></Field>
                                                    {props.errors
                                                        .address_line_2 &&
                                                        props.touched
                                                            .address_line_2 && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .address_line_2
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="col-md-4">
                                                    <Field
                                                        component={
                                                            this.textInput
                                                        }
                                                        label="City"
                                                        name="city"
                                                        placeholder="City"
                                                        type="text"
                                                    ></Field>
                                                    {props.errors.city &&
                                                        props.touched.city && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .city
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="col-md-4">
                                                    <Field
                                                        component={
                                                            this.textInput
                                                        }
                                                        label="State"
                                                        name="state"
                                                        placeholder="State"
                                                        type="text"
                                                    ></Field>
                                                    {props.errors.state &&
                                                        props.touched.state && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .state
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="col-md-4">
                                                    <Field
                                                        component={
                                                            this.textInput
                                                        }
                                                        label="Pincode"
                                                        name="pincode"
                                                        placeholder="Pincode"
                                                        type="number"
                                                    ></Field>
                                                    {props.errors.pincode &&
                                                        props.touched
                                                            .pincode && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .pincode
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>

                                            <button
                                                className={`btn btn-block my-4`}
                                                disabled={
                                                    isSubmitting ? true : false
                                                }
                                                type="submit"
                                            >
                                                {" "}
                                                {isSubmitting ? (
                                                    <LoadingBtn />
                                                ) : (
                                                    "Add Address"
                                                )}
                                            </button>

                                            <a
                                                href="#0"
                                                className={`btn btn-sm btn-outline-secondary my-4`}
                                                onClick={() =>
                                                    this.goBackButton()
                                                }
                                            >
                                                Go Back
                                            </a>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastNotification />
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        isSubmitting: state.commonFunctions.isSubmitting,
    }
}

export default withRouter(
    connect(mapStateToProps, { addNewAddress })(AddNewAddress)
)
