import React from 'react'
import { Link } from 'react-router-dom'

import './error.css'
import { staticURL } from '../../config'
 
const DynamicError = ({ image, title, url, btnText, btn = true }) => {
    return (
        <div className='text-center'>
            <div className='error-img'>
                <img src={`${staticURL}${image}`} alt="404 not found" style={{ margin: '0 auto' }} />
            </div>
            <div className='mt-4'>
                <h4>{title}</h4>
                {
                    btn ? (<Link to={url} className='btn btn-sm btn-dark'>{btnText}</Link>) : null
                }
            </div>
        </div>
    )
}

export default DynamicError
