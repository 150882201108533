import React from 'react'
import { connect } from 'react-redux'

import { baseURL } from '../config'
import { addToCart, removeFromCart, deleteFromCart } from '../../actions'

class CartItem extends React.Component {

    render(){

        const { image, title, price, qty, total, pid } = this.props

        return (
            <div className="d-flex flex-wrap p-3 cart-align001 m-b-3">
                <div className="p-2">
                    <img src={`${baseURL}${image}`} alt="product" />
                </div>
                <div className="pl-5 flex-grow-1">
                    <h5>
                        {title}
                    </h5>
                    <p>₹{price}</p>
                </div>
                <div className="p10 pt10">
                    <div className="product-single-qty">
                        <div className='input-group bootstrap-touchspin bootstrap-touchspin-injected'>
                            <span className="input-group-btn input-group-prepend">
                                <button onClick={() => this.props.removeFromCart(pid) } className="btn btn-outline btn-down-icon bootstrap-touchspin-down" type="button"></button>
                            </span>
                            <input className="horizontal-quantity form-control" type="text" readonly="true" value={qty} />
                            <span className="input-group-btn input-group-append">
                                <button onClick={() => this.props.addToCart(pid)} className="btn btn-outline btn-up-icon bootstrap-touchspin-up" type="button"></button>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p10 pt10  ml-auto ">₹{total}</div>
                <div className="p10 pt10">
                    <a href="#0" onClick={() => this.props.deleteFromCart(pid)} className="remove-from-cart">
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userData
    }
}

export default connect(mapStateToProps, { addToCart, removeFromCart, deleteFromCart })(CartItem)
