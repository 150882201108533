import React from 'react'
import { Link } from 'react-router-dom'

import './error.css'

export default function Error404() {
    return (
        <div className='text-center'>
            <div className='error-img'>
                <img src="/assets/custom/404.png" alt="404 not found" style={{ margin: '0 auto', width: '20%' }} />
            </div>
            <div className='mt-4'>
                <h4>Oops! The page you are looking for is not found.</h4>
                <Link to="/" className='btn btn-sm btn-dark'>Go Back To Home</Link>
            </div>
        </div>
    )
}
