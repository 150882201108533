import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { logout } from "../../actions"
import { staticURL } from "../config"

class Sidebar extends React.Component {
    render() {
        const { page } = this.props
        const { login, loginLoading } = this.props.userData

        let myAccountActive = ""
        let myAddressesActive = ""
        let myProfileActive = ""

        if (page === "my-account") {
            myAccountActive = "active"
        } else if (page === "my-addresses") {
            myAddressesActive = "active"
        } else if (page === "my-profile") {
            myProfileActive = "active"
        }

        if (!loginLoading && !login) {
            window.location.href = `${staticURL}login`
        }

        return (
            <aside className="sidebar col-lg-3">
                <div className="widget widget-dashboard">
                    <h3 className="widget-title">My Account</h3>

                    <ul className="list">
                        <li className={`${myAccountActive}`}>
                            <Link to="/my-account">My Orders</Link>
                        </li>
                        <li className={`${myAddressesActive}`}>
                            <Link to="/my-addresses">Address Book</Link>
                        </li>
                        <li className={`${myProfileActive}`}>
                            <Link to="/my-profile">Account Information</Link>
                        </li>
                        <li>
                            <a href="#0" onClick={() => this.props.logout()}>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
    }
}

export default connect(mapStateToProps, { logout })(Sidebar)
