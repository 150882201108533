import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Breadcrumb from '../../includes/breadcrumb/Breadcrumb'
import ProductSingle from '../product-single/ProductSingle'
import { getSubCategoryProducts, loadMoreSubCategoryProducts } from '../../../actions'
import FilterNav from '../filter-nav/FilterNav'
import DynamicError from '../../includes/errors/DynamicError'
import { projectName } from '../../config'

class SubCategoryListings extends Component {

    componentDidMount(){
        this.props.getSubCategoryProducts(this.props.match.params.slug, 15)
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.slug !== this.props.match.params.slug){
            this.props.getSubCategoryProducts(this.props.match.params.slug, 15)
        }
    }

    render() {

        const { products, last_page, current_page, category } = this.props.categoryProducts
        const { myWishlistItems } = this.props.userData

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: category && category.main_category ? category.main_category.name : null,
                url: `/category/${category && category.main_category ? category.main_category.slug : null}`,
                active: false
            },
            {
                id: '3',
                title: category && category.main_category ? category.name : null,
                url: null,
                active: true
            }
        ]

        const productsList = products && products.length > 0 ? products.map(record => {
            const liked_by_user = myWishlistItems.includes(record.product.id) ? true : false
            return (
                <div className="col-6 col-sm-4 col-md-2 box002" key={record.product ? record.product.id : null}>
                    <ProductSingle name={record.product ? record.product.name : null} slug={record.product ? record.product.slug : null} category={category.name} category_slug={`/category/${category.slug}`} retail_price={record.product ? record.product.retail_price : null} selling_price={record.product ? record.product.selling_price : null} image={record.product && record.product.product_image ? record.product.product_image.api_image : null} is_favourite={liked_by_user} pid={record.product ? record.product.id : null} />
                </div>
            )
        }) : (
            <div className='col-md-12'>
                <DynamicError image="assets/custom/open-box.png" title="This category has no books yet. Check again later." url="/" btnText="Go to homepage" />
            </div>
        )

        return (
            <>
                <Helmet>
                    <title>{category && category.main_category ? `${category.name} | ${projectName} ` : `${projectName}`} </title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />

                    <div className="container my-5">
                        {
                            products && products.length > 0 && (
                                <FilterNav title={category && category.main_category ? category.name : null} toggleButton={false} page="sub-category" />
                            )
                        }
                        
                        <div className="row">
                            <div className="col-lg-12 main-content">
                                <div className="row box001">
                                    
                                    {productsList}

                                </div>

                                {
                                    last_page > current_page ? (
                                        <div className="text-center mt-3">
                                            <button className="btn btn-sm btn-primary" onClick={ () => this.props.loadMoreSubCategoryProducts(this.props.match.params.slug, 15, current_page+1) }>Load More</button>
                                        </div>
                                    ) : null
                                }

                            </div>

                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        categoryProducts: state.subCategoryProductsPage,
        userData: state.userData
    }
}

export default withRouter(connect(mapStateToProps, { getSubCategoryProducts, loadMoreSubCategoryProducts })(SubCategoryListings))
