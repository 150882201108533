import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { baseURL, projectName } from '../config'
import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import { getBlogsList, loadMoreBlogPosts } from '../../actions'
import DynamicError from '../includes/errors/DynamicError'
import { scrollToTop } from '../includes/commonFunctions'

class Blogs extends Component {

    componentDidMount(){
        scrollToTop()
        this.props.getBlogsList(15)
    }

    render() {

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'Blogs',
                url: null,
                active: true
            }
        ]

        const { blogs, last_page, current_page } = this.props.blogs

        const blogsList = blogs && blogs.length > 0 ? blogs.map( blog => {
            return(
                <div className="col-md-4 col-sm-6" key={blog.id}>
                    <article className="post">
                        <div className="post-media">
                            <Link to={`blog-details/${blog.id}`}>
                                <img src={`${baseURL}${blog.api_small_image}`} alt={blog.title} />
                            </Link>
                        </div>

                        <div className="post-body" style={{borderBottom: 'none'}}>
                            <div className="post-date">
                                <span className="day">{blog.day}</span>
                                <span className="month">{blog.month}</span>
                            </div>

                            <h2 className="post-title">
                                <Link to={`blog-details/${blog.id}`}>{blog.title}</Link>
                            </h2>

                            <div className="post-content">
                                <p>By: Aank Baak</p>
                            </div>
                        </div>
                    </article>
                </div>
            )
        }) : (
            <div className='col-12'>
                <DynamicError image={`assets/custom/error.png`} title="No posts available. Please check back later." url="/" btnText="Go To Home" />
            </div>
        )

        return (
            <>
                <Helmet>
                    <title>{`Blogs | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container author-list my-5">
                        <div className="row">
                            {blogsList}
                        </div>
                        {
                            last_page > current_page ? (
                                <div className="text-center mt-3">
                                    <button className="btn btn-sm btn-primary" onClick={ () => this.props.loadMoreBlogPosts(6, current_page+1) }>Load More</button>
                                </div>
                            ) : null
                        }
                    </div>
                </main>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        blogs: state.blogData,
    }
}

export default connect(mapStateToProps, { getBlogsList, loadMoreBlogPosts })(Blogs)