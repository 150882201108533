import React from 'react'
import {connect} from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { staticURL } from '../config'


class OrderPlaced extends React.Component {
    render(){
        return (
            <main className="main">

                <div className="about-section form001 bg001">
                    <div className="container ">
                        <div className="row ">
                            <div className="col-md-6 offset-md-3 bg-white p-5">
                                <div className='text-center'>
                                    <img alt="success" src={`${staticURL}assets/images/success.gif`} style={{ margin: '0 auto', width: '100px' }} />
                                    <div className='mt-3 font-weight-bold'>Your Order with Order Id: <span>{ this.props.match.params.id }</span> is successfully placed.</div>
                                    <Link to='/my-account' className='btn btn-sm btn-primary mt-3'>View My Orders</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

export default withRouter(connect()(OrderPlaced))
