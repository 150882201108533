import * as Yup from 'yup';

const AddressSchema = Yup.object().shape({
    name: Yup.string()
     .min(2, 'Too Short!')
     .required('Please enter your name'),
    mobile: Yup.string().min(10, 'Mobile Number must be of 10 digits').max(10, 'Mobile Number must be of 10 digits').required('Please enter your mobile number'),
    address_line_1: Yup.string().required('Please enter your address'),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().required('Please enter your city'),
    state: Yup.string().required('Please enter your state'),
    pincode: Yup.string().min(6, 'Pincode must be of 6 digits').max(6, 'Pincode must be of 6 digits').required('Please enter your pincode'),
})

export default AddressSchema