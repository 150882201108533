import { combineReducers } from "redux"

/* Manage Modal State */
const modalReducer = (
    state = { modalOpen: false, modalName: null },
    action
) => {
    switch (action.type) {
        case "OPEN_MODAL": {
            let newState = {
                ...state,
                modalOpen: true,
                modalName: action.payload,
            }
            return newState
        }
        case "CLOSE_MODAL": {
            let newState = { ...state, modalOpen: false, modalName: null }
            return newState
        }
        default:
            return state
    }
}

/* Homepage Reducer */
const homepageReducer = (
    state = {
        carousel: { loading: true, error: false, data: [] },
        latestProducts: { loading: true, error: false, data: [] },
        featuredAuthors: { loading: true, error: false, data: [] },
        featuredProducts: { loading: true, error: false, data: [] },
        mainCategories: { loading: true, error: false, data: [] },
        bestsellerProducts: { loading: true, error: false, data: [] },
        trendingProducts: { loading: true, error: false, data: [] },
    },
    action
) => {
    switch (action.type) {
        case "GET_HOMEPAGE_CAROUSEL": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    carousel: {
                        ...state.carousel,
                        loading: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        case "GET_LATEST_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    latestProducts: {
                        ...state.latestProducts,
                        loading: false,
                        data: action.payload.data.products,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        case "GET_FEATURED_AUTHORS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    featuredAuthors: {
                        ...state.featuredAuthors,
                        loading: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        case "GET_FEATURED_BOOKS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    featuredProducts: {
                        ...state.featuredProducts,
                        loading: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        case "GET_BESTSELLER_BOOKS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    bestsellerProducts: {
                        ...state.bestsellerProducts,
                        loading: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        case "GET_TRENDING_BOOKS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    trendingProducts: {
                        ...state.trendingProducts,
                        loading: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        case "GET_HOMEPAGE_MAIN_CATEGORIES": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    mainCategories: {
                        ...state.mainCategories,
                        loading: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        default:
            return state
    }
}

const collectionReducer = (
    state = {
        data: [],
        current_page: 1,
        total: 0,
        per_page: 0,
        last_page: 1,
        loading: true,
        error: false,
    },
    action
) => {
    switch (action.type) {
        case "GET_COLLECTION_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    data: action.payload.data.data,
                    current_page: action.payload.data.current_page,
                    total: action.payload.data.total,
                    per_page: action.payload.data.per_page,
                    last_page: action.payload.data.last_page,
                    loading: false,
                    error: false,
                }
                return newState
            } else {
                return state
            }
        }
        case "LOAD_MORE_COLLECTION_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    data: [...state.data, ...action.payload.data.data],
                    current_page: action.payload.data.current_page,
                    total: action.payload.data.total,
                    per_page: action.payload.data.per_page,
                    last_page: action.payload.data.last_page,
                }
                return newState
            } else {
                return state
            }
        }
        default:
            return state
    }
}

/* Common Data Reducer */
const commondataReducer = (
    state = {
        header: {
            loading: true,
            error: false,
            categories: [],
            languages: [],
            logo: null,
        },
        allCategories: {
            loading: true,
            error: false,
            data: [],
            current_page: 1,
            total: 0,
            per_page: 0,
            last_page: 1,
        },
    },
    action
) => {
    switch (action.type) {
        case "GET_COMMON_DATA": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    header: {
                        ...state.header,
                        loading: false,
                        categories: action.payload.data.categories,
                        languages: action.payload.data.languages,
                        logo: action.payload.data.logo,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        case "GET_ALL_MAIN_CATEGORIES": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    allCategories: {
                        ...state.allCategories,
                        loading: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                return state
            }
        }
        default:
            return state
    }
}

/* Common Functions Reducer */
const commonFunctionsReducer = (
    state = {
        isLoading: false,
        isSubmitting: false,
    },
    action
) => {
    switch (action.type) {
        case "START_LOADING": {
            let newState = { ...state, isLoading: true }
            return newState
        }
        case "STOP_LOADING": {
            let newState = { ...state, isLoading: false }
            return newState
        }
        case "START_SUBMITTING": {
            let newState = { ...state, isSubmitting: true }
            return newState
        }
        case "STOP_SUBMITTING": {
            let newState = { ...state, isSubmitting: false }
            return newState
        }
        default:
            return state
    }
}

/* User Reducer */
const userReducer = (
    state = {
        login: false,
        loginLoading: true,
        forgotPassword: false,
        resetOTP: false,
        resetEmail: null,
        resetResend: false,
        resetVerify: false,
        newPassword: false,
        user: {},
        register: false,
        registerError: false,
        myWishlist: [],
        myWishlistItems: [],
        wishlistError: false,
        current_page: 1,
        total: 0,
        per_page: 0,
        last_page: 1,
        cartItems: [],
        addedToCart: false,
        fast_delivery: false,
        addresses: [],
        addressAction: false,
        selectedAddress: null,
        paymentMode: "ONLINE",
        orderPlaced: false,
        orderId: null,
        orders: [],
        orderDetails: null,
    },
    action
) => {
    switch (action.type) {
        case "USER_LOGIN": {
            if (action.payload.status === 200) {
                let newState = { ...state, login: true }
                return newState
            } else {
                let newState = { ...state, login: false }
                return newState
            }
        }
        case "CHECK_USER": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    login: true,
                    loginLoading: false,
                    user: action.payload.data,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    login: false,
                    loginLoading: false,
                    user: {},
                }
                return newState
            }
        }
        case "LOGOUT_USER": {
            if (action.payload.status === 200) {
                let newState = { ...state, login: false, loginLoading: false }
                return newState
            } else {
                let newState = { ...state, login: false, loginLoading: false }
                return newState
            }
        }
        case "FORGOT_PASSWORD": {
            let newState = { ...state, forgotPassword: action.payload }
            return newState
        }
        case "SEND_RESET_OTP": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    resetOTP: true,
                    resetEmail: action.payload.data.email,
                }
                return newState
            } else {
                let newState = { ...state, resetOTP: false, resetEmail: null }
                return newState
            }
        }
        case "RESEND_RESET_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, resetResend: true }
                return newState
            } else {
                let newState = { ...state, resetResend: false }
                return newState
            }
        }
        case "CHANGE_PASSWORD": {
            if (action.payload.status === 200) {
                let newState = { ...state, newPassword: true }
                return newState
            } else {
                let newState = { ...state, newPassword: false }
                return newState
            }
        }
        case "VERIFY_RESET_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, resetVerify: true }
                return newState
            } else {
                let newState = { ...state, resetVerify: false }
                return newState
            }
        }
        case "USER_REGISTER": {
            if (action.payload.status === 200) {
                let newState = { ...state, register: true }
                return newState
            } else {
                let newState = {
                    ...state,
                    register: false,
                    registerError: true,
                }
                return newState
            }
        }
        case "GET_WISHLIST_IDS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    myWishlistItems: action.payload.data,
                }
                return newState
            } else {
                return state
            }
        }
        case "ADD_TO_WISHLIST": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    myWishlistItems: [
                        ...state.myWishlistItems,
                        parseInt(action.payload.data.item.product_id),
                    ],
                }
                return newState
            } else {
                return state
            }
        }
        case "REMOVE_FROM_WISHLIST": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    myWishlistItems: state.myWishlistItems.filter(
                        (item) => item !== action.payload.data.item.product_id
                    ),
                }
                return newState
            } else {
                return state
            }
        }
        case "MY_WISHLIST": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    myWishlist: action.payload.data.data,
                    current_page: action.payload.data.current_page,
                    total: action.payload.data.total,
                    per_page: action.payload.data.per_page,
                    last_page: action.payload.data.last_page,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    wishlistError: true,
                    myWishlist: [],
                    current_page: 1,
                    total: 0,
                    per_page: 0,
                    last_page: 1,
                }
                return newState
            }
        }
        case "MY_CART": {
            if (action.payload.status === 200) {
                let newState = { ...state, cartItems: action.payload.data }
                return newState
            } else {
                return state
            }
        }
        case "ADD_TO_CART": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    cartItems: action.payload.data.cart_items,
                }
                return newState
            } else {
                return state
            }
        }
        case "REMOVE_FROM_CART": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    cartItems: action.payload.data.cart_items,
                }
                return newState
            } else {
                return state
            }
        }
        case "DELETE_FROM_CART": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    cartItems: action.payload.data.cart_items,
                }
                return newState
            } else {
                return state
            }
        }
        case "MY_ADDRESSES": {
            if (action.payload.status === 200) {
                let newState = { ...state, addresses: action.payload.data }
                return newState
            } else {
                return state
            }
        }
        case "ADD_NEW_ADDRESS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    addresses: [...state.addresses, action.payload.data],
                    addressAction: true,
                }
                return newState
            } else {
                return state
            }
        }
        case "UPDATE_ADDRESS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    addresses: action.payload.data.addresses,
                    addressAction: true,
                }
                return newState
            } else {
                return state
            }
        }
        case "SELECT_ADDRESS": {
            let newState = { ...state, selectedAddress: action.payload }
            return newState
        }
        case "DELETE_ADDRESS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    addresses: state.addresses.filter(
                        (item) => item.id !== action.payload.data.address.id
                    ),
                    addressAction: true,
                }
                return newState
            } else {
                return state
            }
        }
        case "PAYMENT_MODE": {
            let newState = { ...state, paymentMode: action.payload }
            return newState
        }
        case "FAST_DELIVERY": {
            let newState = { ...state, fast_delivery: action.payload }
            return newState
        }
        case "PLACE_ORDER": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    orderPlaced: true,
                    orderId: action.payload.data.order_id,
                }
                return newState
            } else {
                return state
            }
        }
        case "MY_ORDERS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    orders: action.payload.data.data,
                    current_page: action.payload.data.current_page,
                    total: action.payload.data.total,
                    per_page: action.payload.data.per_page,
                    last_page: action.payload.data.last_page,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    orders: [],
                    current_page: 1,
                    total: 0,
                    per_page: 0,
                    last_page: 1,
                }
                return newState
            }
        }
        case "ORDER_DETAILS": {
            if (action.payload.status === 200) {
                let newState = { ...state, orderDetails: action.payload.data }
                return newState
            } else {
                return state
            }
        }
        default:
            return state
    }
}

/* Category Product Listing Reducer */
const categoryProductsReducer = (
    state = {
        category: {},
        products: [],
        current_page: 1,
        total: 0,
        per_page: 0,
        last_page: 1,
    },
    action
) => {
    switch (action.type) {
        case "GET_CATEGORY_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    category: action.payload.data.category,
                    products: action.payload.data.products.data,
                    current_page: action.payload.data.products.current_page,
                    total: action.payload.data.products.total,
                    per_page: action.payload.data.products.per_page,
                    last_page: action.payload.data.products.last_page,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    category: {},
                    products: [],
                    current_page: 1,
                    total: 0,
                    per_page: 0,
                    last_page: 1,
                }
                return newState
            }
        }
        case "LOAD_MORE_CATEGORY_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    products: [
                        ...state.products,
                        ...action.payload.data.products.data,
                    ],
                    current_page: action.payload.data.products.current_page,
                    total: action.payload.data.products.total,
                    per_page: action.payload.data.products.per_page,
                    last_page: action.payload.data.products.last_page,
                }
                return newState
            } else {
                return state
            }
        }
        default:
            return state
    }
}

/* Sub Category Product Listing Reducer */
const subCategoryProductsReducer = (
    state = {
        category: {},
        products: [],
        current_page: 1,
        total: 0,
        per_page: 0,
        last_page: 1,
    },
    action
) => {
    switch (action.type) {
        case "GET_SUB_CATEGORY_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    category: action.payload.data.category,
                    products: action.payload.data.products.data,
                    current_page: action.payload.data.products.current_page,
                    total: action.payload.data.products.total,
                    per_page: action.payload.data.products.per_page,
                    last_page: action.payload.data.products.last_page,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    category: {},
                    products: [],
                    current_page: 1,
                    total: 0,
                    per_page: 0,
                    last_page: 1,
                }
                return newState
            }
        }
        case "LOAD_MORE_SUB_CATEGORY_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    products: [
                        ...state.products,
                        ...action.payload.data.products.data,
                    ],
                    current_page: action.payload.data.products.current_page,
                    total: action.payload.data.products.total,
                    per_page: action.payload.data.products.per_page,
                    last_page: action.payload.data.products.last_page,
                }
                return newState
            } else {
                return state
            }
        }
        default:
            return state
    }
}

/* Product Details Reducer */
const productDetailsReducer = (
    state = {
        product: {},
        related_products: [],
    },
    action
) => {
    switch (action.type) {
        case "GET_PRODUCT_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    product: action.payload.data.product,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    product: {},
                }
                return newState
            }
        }
        case "GET_RELATED_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    related_products: action.payload.data,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    related_products: [],
                }
                return newState
            }
        }
        default:
            return state
    }
}

/* Language Product Listing Reducer */
const languageProductsReducer = (
    state = {
        language: {},
        products: [],
        current_page: 1,
        total: 0,
        per_page: 0,
        last_page: 1,
    },
    action
) => {
    switch (action.type) {
        case "GET_LANGUAGE_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    language: action.payload.data.language,
                    products: action.payload.data.products.data,
                    current_page: action.payload.data.products.current_page,
                    total: action.payload.data.products.total,
                    per_page: action.payload.data.products.per_page,
                    last_page: action.payload.data.products.last_page,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    language: {},
                    products: [],
                    current_page: 1,
                    total: 0,
                    per_page: 0,
                    last_page: 1,
                }
                return newState
            }
        }
        default:
            return state
    }
}

/* Authors Data Reducer */
const authorsReducer = (
    state = {
        authors: [],
        current_page: 1,
        total: 0,
        per_page: 0,
        last_page: 1,
        author_details: { loading: true, error: false, data: {} },
    },
    action
) => {
    switch (action.type) {
        case "GET_AUTHORS_LIST": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    authors: [...state.authors, ...action.payload.data.data],
                    current_page: action.payload.data.current_page,
                    total: action.payload.data.total,
                    per_page: action.payload.data.per_page,
                    last_page: action.payload.data.last_page,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    authors: [],
                    current_page: 1,
                    total: 0,
                    per_page: 0,
                    last_page: 1,
                }
                return newState
            }
        }
        case "GET_AUTHOR_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    author_details: {
                        loading: false,
                        error: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    author_details: { loading: false, error: true, data: {} },
                }
                return newState
            }
        }
        default:
            return state
    }
}

/* Blogs Data Reducer */
const blogsReducer = (
    state = {
        blogs: [],
        current_page: 1,
        total: 0,
        per_page: 0,
        last_page: 1,
        blog_details: { loading: true, error: false, data: {} },
    },
    action
) => {
    switch (action.type) {
        case "GET_BLOGS_LIST": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    blogs: action.payload.data.data,
                    current_page: action.payload.data.current_page,
                    total: action.payload.data.total,
                    per_page: action.payload.data.per_page,
                    last_page: action.payload.data.last_page,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    blogs: [],
                    current_page: 1,
                    total: 0,
                    per_page: 0,
                    last_page: 1,
                }
                return newState
            }
        }
        case "GET_BLOG_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    blog_details: {
                        loading: false,
                        error: false,
                        data: action.payload.data,
                    },
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    blog_details: { loading: false, error: true, data: {} },
                }
                return newState
            }
        }
        case "LOAD_MORE_BLOG_POSTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    blogs: [...state.blogs, ...action.payload.data.data],
                    current_page: action.payload.data.current_page,
                    total: action.payload.data.total,
                    per_page: action.payload.data.per_page,
                    last_page: action.payload.data.last_page,
                }
                return newState
            } else {
                return state
            }
        }
        default:
            return state
    }
}

export default combineReducers({
    modal: modalReducer,
    homePage: homepageReducer,
    commonData: commondataReducer,
    commonFunctions: commonFunctionsReducer,
    userData: userReducer,
    categoryProductsPage: categoryProductsReducer,
    subCategoryProductsPage: subCategoryProductsReducer,
    authorData: authorsReducer,
    languageProductsData: languageProductsReducer,
    productDetailsData: productDetailsReducer,
    blogData: blogsReducer,
    collectionData: collectionReducer,
})
