import React from 'react';

export const LeftArrow = props => {
    const { style, onClick } = props;
    return (
        <div
            className="slick-arrow prev-arrow"
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
            
           
        </div>
    )
}

export const RightArrow = props => {
    const { style, onClick } = props;
    return (
        <div
            className="slick-arrow next-arrow"
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </div>          
        </div>
    )
}
