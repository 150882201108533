import React, { Component } from "react"
import { Helmet } from "react-helmet"

import Breadcrumb from "../includes/breadcrumb/Breadcrumb"
import { projectName } from "../config"

class About extends Component {
    render() {
        const breadcrumbData = [
            {
                id: "1",
                title: "Home",
                url: "/",
                active: false,
            },
            {
                id: "2",
                title: "About",
                url: null,
                active: true,
            },
        ]

        return (
            <>
                <Helmet>
                    <title>{`About | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center">About</h3>
                                <p>
                                    Aank Baak is an independent book publishing
                                    house established in 2007. We are based in
                                    Guwahati, in the state of Assam, in
                                    Northeast India.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default About
