import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getMainCategoryProducts, getSubCategoryProducts, getCollectionProducts } from '../../../actions'

class FilterNav extends Component {

    toggleFilter = () => {
        const elem = document.querySelector(".filter-toggle")

        if(elem.classList.contains('opened')){
            elem.classList.remove('opened')
            document.querySelector("main").classList.remove("sidebar-opened")
        }else{
            elem.classList.add('opened')
            document.querySelector("main").classList.add("sidebar-opened")
        }
    }

    filterProducts = (event, page) => {
        let select = document.querySelector("#filter-products")
        let sort_type = select.options[select.selectedIndex].getAttribute("data-type")
        
        if(page == 'main-category'){
            this.props.getMainCategoryProducts(this.props.match.params.slug, 15, sort_type, event.target.value)
        }else if(page == 'sub-category'){
            this.props.getSubCategoryProducts(this.props.match.params.slug, 15, sort_type, event.target.value)
        }else if(page == 'collection'){
            this.props.getCollectionProducts(18, sort_type, event.target.value)
        }
    }

    render() {

        const { toggleButton, page, title } = this.props

        return (
            <nav className="toolbox horizontal-filter">

                <div className="toolbox-left d-none d-lg-block">
                    {
                        toggleButton ? (
                            <div className="toolbox-item filter-toggle">
                                <span>Filters:</span>
                                <a href="#0" onClick={() => this.toggleFilter()}>&nbsp;</a>
                            </div>
                        ) : null
                    }
                    
                    {
                        title ? (
                            <h4 style={{ marginTop: '1rem' }}>{title}</h4>
                        ) : null
                    }

                </div>

                <div className="toolbox-item toolbox-sort ml-lg-auto">
                    <label>Sort By:</label>
                    <div className="select-custom">
                        <select name="orderby" id="filter-products" className="form-control" onChange={ (e) => this.filterProducts(e, page) }>
                            <option value="">Default</option>
                            <option value="asc" data-type="name" >Title: A-Z</option>
                            <option value="desc" data-type="name">Title: Z-A</option>
                            <option value="asc" data-type="selling_price">Price: Low to High</option>
                            <option value="desc" data-type="selling_price">Price: High to Low</option>
                        </select>
                    </div>
                </div>
            </nav>
        )
    }
}

export default withRouter(connect(null, { getMainCategoryProducts, getSubCategoryProducts, getCollectionProducts })(FilterNav))