import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
    getCommonData,
    checkUser,
    getWishlistIds,
    getMyCart,
} from "../../actions"
import TopBar from "./TopBar"
import { baseURL } from "../config"

class Header extends Component {
    componentDidMount() {
        this.props.getCommonData()
        this.props.checkUser()
        this.props.getWishlistIds()
        this.props.getMyCart()
    }

    render() {
        const { loading, categories, languages, logo } = this.props.headerData
        const { login, cartItems } = this.props.userData

        const headerCategories =
            !loading && categories.length > 0
                ? categories.map((category) => {
                      return (
                          <div
                              key={category.id}
                              className={`${
                                  categories.length === 1
                                      ? `col-md-12`
                                      : categories.length === 2
                                      ? `col-md-6`
                                      : `col-md-4`
                              }`}
                          >
                              <Link
                                  to={`/category/${category.slug}`}
                                  style={{ cursor: "pointer" }}
                                  className="nolink"
                                  title={category.name}
                              >
                                  {category.name}
                              </Link>
                              {category.subcategories &&
                              category.subcategories.length > 0 ? (
                                  <ul className="submenu">
                                      {category.subcategories.map(
                                          (subcategory) => {
                                              return (
                                                  <li key={subcategory.id}>
                                                      <Link
                                                          to={`/sub-category/${subcategory.slug}`}
                                                          className="ellipsis"
                                                          title={
                                                              subcategory.name
                                                          }
                                                      >
                                                          {subcategory.name}
                                                      </Link>
                                                  </li>
                                              )
                                          }
                                      )}
                                  </ul>
                              ) : null}
                          </div>
                      )
                  })
                : null

        const headerLanguages =
            !loading && languages.length > 0
                ? languages.map((language) => {
                      return (
                          <li key={language.id}>
                              <Link to={`/language/${language.slug}`}>
                                  {language.name}
                              </Link>
                          </li>
                      )
                  })
                : null

        return (
            <header className="header">
                <TopBar />
                <div className="header-middle">
                    <div className="container">
                        <div className="header-left col-lg-2 w-auto pl-0 mr-3">
                            <button
                                className="mobile-menu-toggler"
                                type="button"
                            >
                                <i className="icon-menu"></i>
                            </button>
                            <Link to="/" className="logo">
                                <img
                                    src={`${baseURL}${logo}`}
                                    alt="Aaankbaak Logo"
                                    className=""
                                    style={{ maxWidth: "150px" }}
                                />
                            </Link>
                        </div>
                        {/*  End .header-left */}
                        <div className="header-right w-lg-max">
                            <div className="header-icon header-search header-search-inline header-search-category mr-lg-5 pr-lg-4 w-lg-max">
                                <a
                                    href="#0"
                                    className="search-toggle"
                                    role="button"
                                >
                                    <i className="icon-search-3"></i>
                                </a>
                                <form action="#" method="get">
                                    <div className="header-search-wrapper">
                                        <input
                                            type="search"
                                            className="form-control"
                                            name="q"
                                            id="q"
                                            placeholder="Search For Your Favourite Books"
                                            required=""
                                        />

                                        <button
                                            className="btn icon-search-3"
                                            type="submit"
                                        ></button>
                                    </div>
                                    {/* End .header-search-wrapper */}
                                </form>
                            </div>
                            {/* End .header-search */}
                            {/* <div className="header-contact d-none d-lg-flex pl-1 mr-xl-5 pr-4">
                                <i className="icon-user-2"></i>
                                <h6>Hello, Sign In<a href="login.html" className="text-dark font3">My Account</a></h6>
                            </div> */}
                            {login ? (
                                <Link
                                    to="/my-account"
                                    className="header-icon pl-1"
                                    title="My Account"
                                >
                                    <i className="icon-user-2"></i>
                                </Link>
                            ) : (
                                <Link
                                    to="/login"
                                    className="header-icon pl-1"
                                    title="My Account"
                                >
                                    <i className="icon-user-2"></i>
                                </Link>
                            )}
                            <Link
                                to="/my-wishlist"
                                className="header-icon pl-1"
                                title="My Wishlist"
                            >
                                <i className="icon-wishlist-2"></i>
                            </Link>
                            <Link
                                to="/cart"
                                className="header-icon pl-1"
                                title="Cart"
                            >
                                <i className="icon-shopping-cart"></i>
                                {cartItems && cartItems.length > 0 ? (
                                    <span className="cart-count badge-circle">
                                        {cartItems.length}
                                    </span>
                                ) : null}
                            </Link>
                        </div>
                        {/* End .header-right */}
                    </div>
                    {/* End .container */}
                </div>
                {/* End .header-middle */}
                <div
                    className="header-bottom sticky-header d-none d-lg-block"
                    data-sticky-options="{
                    'move': [
                        {
                            'item': '.header-search',
                            'position': 'end',
                            'clone': false
                        },
                        {
                            'item': '.header-icon:not(.header-search)',
                            'position': 'end',
                            'clone': false
                        },
                        {
                            'item': '.cart-dropdown',
                            'position': 'end',
                            'clone': false
                        }
                    ],
                    'moveTo': '.container',
                    'changes': [
                        {
                            'item': '.header-search',
                            'removeClass': 'header-search-inline w-lg-max mr-lg-5 pr-lg-4',
                            'addClass': 'header-search-popup ml-auto'
                        },
                        {
                            'item': '.main-nav',
                            'removeClass': 'w-100'
                        },
                        {
                            'item': '.logo',
                            'removeClass': 'd-none'
                        },
                        {
                            'item': '.float-right',
                            'addClass': 'd-none'
                        }
                    ]
                }"
                >
                    <div className="container">
                        <nav className="main-nav w-100">
                            <ul className="menu">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/collection">Collection</Link>
                                </li>
                                <li>
                                    <a href="#0">Categories</a>
                                    <div
                                        className={`megamenu megamenu-fixed-width ${
                                            categories.length === 1
                                                ? `megamenu-1cols`
                                                : categories.length === 2
                                                ? `megamenu-2cols`
                                                : `megamenu-3cols`
                                        }`}
                                    >
                                        {/* megamenu-1cols megamenu-3cols*/}
                                        <div className="row">
                                            {headerCategories}

                                            {categories.length > 3 ? (
                                                <div className="col-lg-12 d-flex justify-content-end">
                                                    <Link to="#0">
                                                        View All
                                                    </Link>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* End .megamenu */}
                                </li>

                                {/* <li>
                                    <a href="#0">Languages</a>
                                    <ul>
                                        { headerLanguages }
                                    </ul>
                                </li> */}
                                <li>
                                    <Link to={`/blogs`}>Blogs</Link>
                                </li>
                                <li>
                                    <Link to={`/authors`}>Authors</Link>
                                </li>
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                                {/* <li className="float-right bg-dark2"><a className="text-white" href="#0">Buy Porto!</a></li>
                                <li className="float-right"><a className="text-white" href="#0">Special Offer!</a></li> */}
                            </ul>
                        </nav>
                    </div>
                    {/* End .container */}
                </div>
                {/* End .header-bottom */}
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        headerData: state.commonData.header,
        userData: state.userData,
    }
}

export default connect(mapStateToProps, {
    getCommonData,
    checkUser,
    getWishlistIds,
    getMyCart,
})(Header)
