import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { logout } from '../../actions'
import { projectName } from '../config'

class TopBar extends Component {
    render() {

        const { login, loginloading } = this.props.userData

        return (
            <div className="header-top d-none d-lg-block">
                <div className="container">
                    <div className="header-right">
                        <p className="top-message text-uppercase d-none d-sm-block">{projectName}</p>

                        <span className="separator"></span>

                        <div className="header-dropdown dropdown-expanded mx-3 px-1">
                            <a href="#0">Links</a>
                            <div className="header-menu">
                                <ul>
                                    {
                                        login && !loginloading ? (
                                            <>
                                                <li><Link to="/my-account">My Account</Link></li>
                                                <li><a href="#0" onClick={() => this.props.logout()}>Logout</a></li>
                                            </>
                                        ) : (
                                            <li><Link to="/login">Log In</Link></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userData
    }
}

export default connect(mapStateToProps, { logout })(TopBar)
