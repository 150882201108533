import React, { Component } from 'react'
import Slider from "react-slick"
import {connect} from 'react-redux'

import { getHomepageCarousel } from '../../../actions'
import { baseURL } from '../../config'

class Carousel extends Component {

    componentDidMount(){
        this.props.getHomepageCarousel()
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000
        }

        const { loading, data } = this.props.carousel

        const sliderImages = !loading && data.length > 0 ? data.map( slide => {
            return (
                <div key={slide.id}>
                    <a href="#0">
                        <img src={`${baseURL}${slide.api_image}`} alt="Slide" />
                    </a>
                </div>
            )
        }): null

        return (
            <Slider {...settings}>
                {sliderImages}
            </Slider>
        )
    }
}

const mapStateToProps = state => {
    return {
        carousel: state.homePage.carousel
    }
}

export default connect(mapStateToProps, { getHomepageCarousel })(Carousel)