import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import Breadcrumb from '../includes/breadcrumb/Breadcrumb'
import { projectName } from '../config'
import { getAllMainCategories } from '../../actions'
import CategoryItem from '../homepage/categories/CategoryItem'

class AllCategories extends Component {

    componentDidMount(){
        this.props.getAllMainCategories(9)
    }

    render() {

        const { data } = this.props.allCategories

        const breadcrumbData = [
            {
                id: '1',
                title: 'Home',
                url: '/',
                active: false
            },
            {
                id: '2',
                title: 'All Categories',
                url: null,
                active: true
            }
        ]

        const categoriesList = data && data.length > 0 ? data.map( category => {
            return (
                <div className='col-lg-3 col-md-4 col-sm-6 m-b-3'>
                    <CategoryItem key={category.id} title={category.name} image={category.api_image} slug={category.slug} />
                </div>
            )
        }) : null

        return (
            <>
                <Helmet>
                    <title>{`All Categories | ${projectName}`}</title>
                </Helmet>
                <main className="main">
                    <Breadcrumb data={breadcrumbData} />
                    <div className="container my-5">
                        <div className="row">
                            {categoriesList}
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        allCategories: state.commonData.allCategories,
    }
}

export default connect(mapStateToProps, { getAllMainCategories })(AllCategories)