import React from 'react'
import { Link } from 'react-router-dom'

import { baseURL } from '../../config'
import './featured-author.css'

const AuthorItem = ({ id, slug, name, image }) => {
    return (
        <div class="inner-quickview inner-icon px-4" id={`${name}-${id}`}>
            <Link to={`author/${slug}`}>
                <figure> 
                    <img className='catgeory-img-index' src={`${baseURL}${image}`} alt={name} />
                </figure>
                <div class="text-center">
                    <a href="#0">{name}</a>
                </div>
            </Link>
            
        </div>
    )
}

export default AuthorItem