import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Provider } from "react-redux"

import store from "./store/Store" //Redux Store
import "./components/styles/custom.css"
import MobileMenu from "./components/mobile-menu/MobileMenu"
import Header from "./components/header/Header"
import Homepage from "./components/homepage/Homepage"
import Footer from "./components/footer/Footer"
import Login from "./components/auth/login/Login"
import Register from "./components/auth/register/Register"
import CategoryListings from "./components/product-listings/category-listings/CategoryListings"
import SubCategoryListings from "./components/product-listings/sub-category-listings/SubCategoryListings"
import AuthorsList from "./components/authors/AuthorsList"
import ProductDetails from "./components/product-details/ProductDetails"
import AuthorDetails from "./components/authors/author-details/AuthorDetails"
import MyWishlist from "./components/wishlist/MyWishlist"
import MyCart from "./components/cart/MyCart"
import MyAccount from "./components/my-account/MyAccount"
import MyAddresses from "./components/my-account/MyAddresses"
import About from "./components/about/About"
import MyProfile from "./components/my-account/MyProfile"
import Blogs from "./components/blogs/Blogs"
import BlogDetails from "./components/blogs/BlogDetails"
import ContactUs from "./components/contact/ContactUs"
import AllCategories from "./components/categories/AllCategories"
import Collection from "./components/collection/Collection"
import Address from "./components/checkout/Address"
import AddNewAddress from "./components/my-account/AddNewAddress"
import Payment from "./components/checkout/Payment"
import OrderPlaced from "./components/checkout/OrderPlaced"
import OrderDetails from "./components/my-account/OrderDetails"
import EditAddress from "./components/my-account/EditAddress"
import TermsConditions from "./components/policy/TermsConditions"
import DeliveryInformation from "./components/policy/DeliveryInformation"
import PrivacyPolicy from "./components/policy/PrivacyPolicy"
import CancellationPolicy from "./components/policy/CancellationPolicy"

function App() {
    return (
        <Provider store={store}>
            <Router>
                <div className="page-wrapper">
                    <Header />
                    <Switch>
                        {/* Home Page */}
                        <Route exact path="/">
                            <Homepage />
                        </Route>

                        {/* Login Page */}
                        <Route path="/login">
                            <Login />
                        </Route>

                        {/* Register Page */}
                        <Route path="/register">
                            <Register />
                        </Route>

                        {/* Category Product Listing Page */}
                        <Route path="/category/:slug">
                            <CategoryListings />
                        </Route>

                        {/* Sub Category Product Listing Page */}
                        <Route path="/sub-category/:slug">
                            <SubCategoryListings />
                        </Route>

                        {/* Authors List Page */}
                        <Route path="/authors">
                            <AuthorsList />
                        </Route>

                        {/* Authors Details Page */}
                        <Route path="/author/:slug">
                            <AuthorDetails />
                        </Route>

                        {/* Product Details Page */}
                        <Route path="/product/:slug">
                            <ProductDetails />
                        </Route>

                        {/* My Wishlist Page */}
                        <Route path="/my-wishlist">
                            <MyWishlist />
                        </Route>

                        {/* My Cart */}
                        <Route path="/cart">
                            <MyCart />
                        </Route>

                        {/* My Account */}
                        <Route path="/my-account">
                            <MyAccount />
                        </Route>

                        {/* My Addresses */}
                        <Route path="/my-addresses">
                            <MyAddresses />
                        </Route>

                        {/* About */}
                        <Route path="/about">
                            <About />
                        </Route>

                        {/* Profile Information */}
                        <Route path="/my-profile">
                            <MyProfile />
                        </Route>

                        {/* Blogs */}
                        <Route path="/blogs">
                            <Blogs />
                        </Route>

                        {/* BLog Details */}
                        <Route path="/blog-details/:slug">
                            <BlogDetails />
                        </Route>

                        {/* Contact Us */}
                        <Route path="/contact">
                            <ContactUs />
                        </Route>

                        {/* All Categories */}
                        <Route path="/all-categories">
                            <AllCategories />
                        </Route>

                        {/* Collection Page */}
                        <Route path="/collection">
                            <Collection />
                        </Route>

                        {/* Checkout Addresses */}
                        <Route path="/checkout/addresses">
                            <Address />
                        </Route>

                        {/* Checkout Payment */}
                        <Route path="/checkout/payment/:id">
                            <Payment />
                        </Route>

                        {/* Add New Address */}
                        <Route path="/add-new-address">
                            <AddNewAddress />
                        </Route>

                        {/* Edit Address */}
                        <Route path="/edit-address/:id">
                            <EditAddress />
                        </Route>

                        {/* Order Successfully Placed */}
                        <Route path="/order-placed/:id">
                            <OrderPlaced />
                        </Route>

                        {/* Order Details */}
                        <Route path="/order-details/:id">
                            <OrderDetails />
                        </Route>

                        {/* Terms & Conditions */}
                        <Route path="/terms-conditions">
                            <TermsConditions />
                        </Route>

                        {/* Delivery Information */}
                        <Route path="/delivery-information">
                            <DeliveryInformation />
                        </Route>

                        {/* Privacy policy */}
                        <Route path="/privacy-policy">
                            <PrivacyPolicy />
                        </Route>

                        <Route path="/cancellation-refund-policy">
                            <CancellationPolicy />
                        </Route>

                        <Route>
                            <div className="text-center">
                                <h3>404</h3>
                            </div>
                        </Route>
                    </Switch>
                    <Footer />
                </div>
                <MobileMenu />
            </Router>
        </Provider>
    )
}

export default App
