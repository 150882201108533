import React, { Component } from "react"
import { Helmet } from "react-helmet"

import FeaturedAuthors from "./authors/FeaturedAuthors"
import Categories from "./categories/Categories"
import FeaturedProducts from "./featured-products/FeaturedProducts"
import HeaderSection from "./header-section/HeaderSection"
import LatestProducts from "./latest-products/LatestProducts"
import { scrollToTop } from "../includes/commonFunctions"
import { projectName } from "../config"
import BestsellerProducts from "./bestseller-products/BestsellerProducts"
import TrendingProducts from "./trending-products/TrendingProducts"

class Homepage extends Component {
    componentDidMount() {
        scrollToTop()
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {`${projectName}`} | A prominent publishing house in
                        Guwahati-Assam
                    </title>
                </Helmet>
                <main className="main">
                    <HeaderSection />
                    <LatestProducts />
                    <Categories />
                    <TrendingProducts />
                    <FeaturedProducts />
                    <BestsellerProducts />
                    <FeaturedAuthors />
                </main>
            </>
        )
    }
}

export default Homepage
