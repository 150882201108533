import React from 'react'
import { Link } from 'react-router-dom'

import { baseURL } from '../../config'

const CategoryItem = ({ id, slug, title, image }) => {
    return (
        <div class="inner-quickview inner-icon px-4" id={`${title}-${id}`}>
            <Link to={`category/${slug}`}>
                <figure> 
                    <img className='catgeory-img-homepage' src={`${baseURL}${image}`} alt={title} />
                </figure>
                <div class="text-center">
                    <a href="#0">{title}</a>
                </div>
            </Link>
            
        </div>
    )
}

export default CategoryItem
