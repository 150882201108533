import React from "react"

import { baseURL } from "../config"
import "./order-summary.css"

const OrderSummary = ({ cartItems, fastDelivery }) => {
    let grandTotal = 0

    const cart_items = cartItems.map((item) => {
        grandTotal = grandTotal + item.total_amount
        return (
            <tr key={`order-summary-product-${item.product_id}`}>
                <td className="product-col">
                    <figure className="product-image-container">
                        <a href="#0" className="product-image">
                            <img
                                src={`${baseURL}${item.product.product_image.api_image}`}
                                alt={item.product.name}
                            />
                        </a>
                    </figure>
                    <div>
                        <h2
                            className="product-title ellipsis"
                            title={item.product.name}
                        >
                            <a href="#0">{item.product.name}</a>
                        </h2>

                        <span className="product-qty">
                            Qty: {item.quantity}
                        </span>
                        <span className="product-qty">
                            Total: ₹{item.total_amount}
                        </span>
                    </div>
                </td>
                {/* <td className="price-col">₹{item.total_amount}</td> */}
            </tr>
        )
    })

    let delivery = grandTotal > 499 ? 0 : 40
    let fast_delivery = fastDelivery ? 60 : 0

    return (
        <div className="order-summary">
            <h3>Order Summary</h3>

            <h4>
                <a
                    data-toggle="collapse"
                    href="#order-cart-section"
                    className="collapsed"
                    role="button"
                    aria-expanded="false"
                    aria-controls="order-cart-section"
                >
                    {cart_items.length} Product(s)
                </a>
            </h4>

            <div className="collapse show mb-0" id="order-cart-section">
                <table className="table table-mini-cart">
                    <tbody>{cart_items}</tbody>
                </table>
            </div>
            <h4 className="border-top-0 border-bottom-0 mt-1 text-right">
                Sub Total: ₹{grandTotal}
            </h4>
            <h4
                className={`border-top-0 mt-1 text-right ${
                    fastDelivery ? "border-bottom-0" : "pb-3"
                } `}
            >
                Delivery Fee: ₹{delivery}
            </h4>
            {fastDelivery && (
                <h4 className="border-top-0 mt-1 text-right pb-3">
                    Fast Delivery Fee: ₹{fast_delivery}
                </h4>
            )}
            <h5 className="mt-1">
                Grand Total: ₹{grandTotal + delivery + fast_delivery}
            </h5>
        </div>
    )
}

export default OrderSummary
