import React from 'react'
import { Link } from 'react-router-dom'
import { baseURL } from '../../config/index'

const SingleAuthor = ({ slug, name, image }) => {
    return (
        <div className="col-6 col-sm-3">
            <div className="product-default single-author">
                <Link to={`/author/${slug}`}>
                    <div className="bg">
                        <img src={`${baseURL}${image}`} alt={name} />
                    </div>
                    <div className="product-details">
                        
                        <h2 className="product-title">
                            {name}
                        </h2>

                        <div className="category-wrap">
                            <div className="category-list">
                                View Details
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default SingleAuthor
