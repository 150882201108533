import React, { Component } from 'react'

import Carousel from './Carousel'

class HeaderSection extends Component {
    render() {
        return (
            <div className="home-top-container mt-lg-2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 mb-2">
							<Carousel />
						</div>

						{/* <div className="col-lg-3 top-banners">
							<div className="row">
								<div className="col-6 col-md-6 col-lg-12">
									<div className="banner banner1 banner-md-vw-large banner-sm-vw-large mb-2">
										<figure>
											<img src="./assets/images/banners/banner-1.jpeg" alt="banner" />
										</figure>
									</div>
								</div>
								
								<div className="col-6 col-md-6 col-lg-12">
									<div className="banner banner3 banner-md-vw-large banner-sm-vw-large mb-2">
										<figure>
											<img src="./assets/images/banners/banner-3.jpeg" alt="banner" style={{ objectPosition: 'left' }} />
										</figure>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
        )
    }
}

export default HeaderSection